import Link from "next/link";
import Image from "next/image";
import styles from "./GameChangeTab.module.scss";
import { Base } from "@/types/PropTypes";
import { LogoTabProps, OtherSiteTabProps, SudokuProTabProps } from "./types";
import { Logo } from "../common";
import sudokuProIcon from "/public/images/sudokuProIcon.png";
import mahjongIcon from "/public/images/mahjongIcon.png";
import omiPlayIcon from "/public/images/omiPlayIcon.png";

export const Wrapper: React.FC<Base> = ({ children }) => <div className={styles.wrapper}>{children}</div>;

export const TabsWrapper: React.FC<Base> = ({ children }) => <div className={styles.tabsWrapper}>{children}</div>;

export const LogoTab: React.FC<LogoTabProps> = ({ onClick }) => <Logo section="header" onClick={onClick} />;

export const SudokuProTab: React.FC<SudokuProTabProps> = ({ title, homePath, onClick }) => (
  <Link href={homePath} className={styles.sudokuProTab} onClick={onClick}>
    <Image src={sudokuProIcon} width={24} height={24} alt="SudokuPro icon" className={styles.tabIcon} priority />
    <p className={styles.sudokuProTabText}>{title}</p>
  </Link>
);

export const MahjongTab: React.FC<OtherSiteTabProps> = ({ title, lang }) => (
  <Link href={`https://mahjong-online.club/${lang}`} className={styles.otherSiteTab}>
    <Image src={mahjongIcon} width={24} height={24} alt="mahjong-online icon" className={styles.tabIcon} priority />
    <p className={styles.mahjongTabText}>{title}</p>
  </Link>
);

export const MoreGamesTab: React.FC<OtherSiteTabProps> = ({ title }) => (
  <Link
    href={`https://omiplay.com?utm_campaign=sudoku-header&utm_medium=referal&utm_source=sudokupro.app`}
    className={styles.otherSiteTab}
    target="_blank"
  >
    <Image src={omiPlayIcon} width={24} height={24} alt="OmiPlay icon" className={styles.tabIcon} priority />
    <p className={styles.omiPlayTabText}>{title}</p>
  </Link>
);

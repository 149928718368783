export const pl: any = {
  ISOcode: "pl",
  countryName: "Poland",
  language: "Polski",

  headerAndFooter: {
    moreGames: "Więcej gier",
    playSudoku: "Zagraj w Sudoku",
    playSolitaire: "Zagraj w pasjansa",
    playMahjong: "Zagraj w Mahjonga",
  },

  blog: "Blog",
  dailyChallenges: "Codzienne wyzwania",
  dailyChallenge: "WYZWANIE DNIA",
  readBlog: "Czytaj blog",
  omisoft: "OmiSoft",
  sudokuPuzzleGame: "Sudoku Pro - Gra logiczna",
  sudokuTipsAndTrick: "Sudoku Online - rady oraz wskazówki albo jak zostać profesjonalistą",
  oops: "Ups...",
  errorPageNotFound: "Błąd 404. Strone nie znaleziono",
  sorryGoBackHome: "Przepraszamy, nie można znaleźć żądanej strony. Proszę wrócić do strony głównej.",
  backToHomepage: "POWRÓT DO STRONY GŁÓWNEJ",
  cookieText:
    "Nasza strona internetowa wykorzystuje pliki cookies w celu personalizacji treści oraz analizy naszego ruchu, co może również skutkować profilowaniem.",
  ok: "OK",
  privacyPolicy: "Polityka prywatności",
  howToPlay: "Jak grać",
  undo: "Anuluj",
  notes: "Notatki",
  hints: "Podpowiedzi",
  erase: "Usuń",
  newGame: "NOWA GRA",
  level: "Poziom: ",
  levels: "Poziomy",

  similarArticles: "Podobne artykuły",
  playSudokuOnline: "Zagraj w Sudoku Online",
  //   startYourBrainTraining:
  //     "Start your brain training now, download the free online game Sudoku Pro, and don’t complain that you can’t stop solving.",
  nowDownload: "POBIERZ TERAZ POBIERZ TERAZ POBIERZ TERAZ POBIERZ TERAZ POBIERZ TERAZ POBIERZ TERAZ POBIERZ TERAZ",
  playBest: "Graj najlepiej",
  online: "online",
  playSudoku: "Sudoku!",
  seeWeToldYou: "Widzisz, mówiliśmy Ci, że zasady są proste!",
  dontWasteYourTime: "Teraz nie trać czasu na wątpliwości, najlepszą aplikacją ",
  appIsSudokuPro: "do sudoku jest Sudoku Pro i musisz ją wypróbować!",
  goBack: "Powrót",
  facebook: "Facebook",
  twitter: "Twitter",
  telegram: "Telegram",
  points: "zwrotnica",
  yes: "Tak",
  no: "NIE",
  playMahjong: "Zagraj w Mahjong online",
  siteMap: "Mapa serwisu",
  otherGame: "Inne gry",
  historySudoku: "Historia sudoku",
  rulesSudoku: "Zasady Sudoku",
  tipsSudoku: "Wskazówki dotyczące sudoku",
  /////Win poup////
  youWin: "Zwycięstwo!",
  youWinText: "Pobierz aplikację, aby grać w dowolnym momencie i bez żadnych ograniczeń",
  followUs: "Śledźcie nas w mediach społecznościowych:",
  winLevel: "Poziom",
  startNewGame: "Rozpocznij nową grę",
  time: "Czas",
  bestResult: "Najlepszy wynik",
  //   ////////////////////////
  playOnline: "graj online",
  cancel: "Anuluj",
  //////// Meta and Description /////////
  metaPageTitle: "Jak sie gra w sudoku — od nooba do Sudoku Pro",
  metaPageDescription:
    "Zajrzyj na blog Sudoku Pro, aby znaleźć cenne rekomendacje i ważne wskazówki, które pomogą Ci grać w sudoku online jak prawdziwy profesjonalista, bez względu na obecny poziom umiejętności. Dzięki jasnym objaśnieniom złożonych strategii gry logicznej sudoku, zrozumiesz, jak grać wydajniej i poprawić swoją pamięć.",

  metaBlogTitle: "Sudoku Online - rady oraz wskazówki | Od nooba do sudoku Pro",
  metaBlogDescription:
    "Zapraszamy na nasz Sudoku Pro blog, żeby jako pierwszy dowiadywać się o wszystkich specyfikacjach sudoku dla początkujących oraz doświadczonych graczy. Otrzymaj proste wytłumaczenie profesjonalnych strategii i rady dotyczące rozwiązywania sudoku online. ",

  ////Basic rules///////

  gameRules: {
    subtitle: "Sudoku jest proste",
    title: "Podstawowe zasady gry w Sudoku",
    titleText:
      "Jeśli jesteś nowicjuszem w świecie Sudoku, nie ma się czego wstydzić. Ta gra jest bardzo logiczna i łatwa do zrozumienia. Istnieją 3 podstawowe zasady i w zależności od rodzaju, który wybierzesz mogą być modyfikacje, jednak podstawowy zestaw zasad rzadko się zmienia.",
    goal: "Cel:",
    goalText:
      "Wypełnienie siatki 9×9, tak aby każda kolumna, rząd i sekcja 3×3 zawierała cyfry od 1 do 9. Tradycyjnie nie ma limitu czasu, ale niektóre gry Sudoku dodają ograniczenia czasowe, które wymuszają do szybszego myślenia.",
    ruleTitle: "Jak grać w Sudoku?",
    ruleText:
      "Kiedy zaczniesz grę, niektóre kwadraty siatki 9×9 będą już miały cyfry. Twoim zadaniem jest użyć całej siły mózgu, aby wypełnić brakujące miejsca i uzupełnić siatkę.",
    fail: "Nie udało Ci się, jeśli:",
    list: "Każdy rząd ma dwie takie same liczby <list> W każdej kolumnie są dwie takie same liczby <list> Każda siatka 3×3 ma dwie takie same liczby",
    remember: "Pamiętaj",
    rememberText: "Każda cyfra od 1 do 9 może być użyta tylko raz w rzędzie, kolumnie lub siatce 3×3.",
  },

  playNow: "ZAGRAJ TERAZ",
  EASY: "Łatwy",
  MEDIUM: "Średni",
  HARD: "Trudny",
  EXPERT: "Ekspert",
  EXTREME: "Ekstremalny",
  /////////// Months ////////
  January: "Styczeń",
  February: "Luty",
  March: "Marzec",
  April: "Kwiecień",
  May: "Maj",
  June: "Czerwiec",
  July: "Lipiec",
  August: "Sierpień",
  September: "Wrzesień",
  October: "Październik",
  November: "Listopad",
  December: "Grudzień",
  /////////NoGame RC//////
  sorry: "przepraszam",
  gameNotFount: "gra nie znaleziona",
  chooseChallenge: "Wybierz wyzwanie tutaj:",
  ///// languages///////
  english: "English",
  ukrainian: "Ukrainian",
  deutsch: "German",
  polish: "Polish",
  spanish: "Hiszpański",

  // Login Page
  loginMetaTitle: "Zaloguj się do Sudoku Pro",
  loginMetaDescription: "Zaloguj się, aby zapisać postęp gry i rywalizować z innymi graczami",
  logIn: "Zaloguj sie",
  welcomeBack: "Witamy spowrotem",
  continueWithGoogle: "Kontynuuj z Google",
  continueWithFacebook: "Kontynuuj na Facebooku",
  authorizationFailedMessage: "Autoryzacja nie powiodła się. Spróbuj ponownie później",

  // Registration Page
  registrationMetaTitle: "Zakończ rejestrację - Sudoku Pro",
  registrationMetaDescription:
    "Pozostał już tylko jeden mały krok, aby dokończyć rejestrację i w pełni wykorzystać możliwości gry Sudoku",
  createAnAccount: "Utwórz konto",
  nickName: "Przezwisko",
  country: "Kraj",
  signUp: "Zapisać się",
  createYourNickName: "Stwórz swój pseudonim",
  registrationFailedMessage: "Rejestracja nie powiodła się. Spróbuj ponownie później",

  // Logout Dialog
  logOutMessage: "Czy na pewno chcesz się wylogować? Twoje postępy nie zostaną zapisane",
  logOut: "Wyloguj",

  // Awards Page
  awardsMetaTitle: "Twoje nagrody — Sudoku Pro",
  awardsMetaDescription: "Wykonuj codzienne wyzwania w Sudoku Pro i zbierz wszystkie nagrody za ten rok!",
  awards: "Nagrody",
  yourAwards: "Twoje nagrody",
  yourAwardsDescription: "Wykonuj codzienne wyzwania i zdobywaj nagrody na koniec każdego miesiąca!",
  awardsDontMissASingleDay: "Nie przegap ani jednego dnia – zagraj w sudoku!",

  // Monthly Award Dialog
  awardDialogTitle: "Otrzymana Nagroda Miesięczna",
  awardDialogDescription: "Ukończyłeś wszystkie codzienne wyzwania przez miesiąc i otrzymałeś nagrodę! Gratulacje!",
  awardShare: "Udział",

  // Authorization banner
  authorizationBannerMessage: "Zaloguj się, aby zapisać swoje postępy i nagrody 🏆",

  // You Won Dialog
  levelsLeftMessage: "Pozostały poziom do zdobycia nagrody: %s",

  // Ratings Block
  topCountriesToday: "TOP 20 krajów dzisiaj",
  topUsersToday: "TOP 100 użytkowników dzisiaj",
  numberOfUsers: "Liczba użytkowników",
  updatedEvery24hours: "Aktualizowane co 24 godziny",
  experiencePoints: "Punkty doświadczenia",
  showFullList: "Pokaż pełną listę",
  showShortList: "Pokaż krótką listę",
};

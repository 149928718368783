export const pt: any = {
  ISOcode: "pt",
  countryName: "Portugal",
  language: "Português",

  headerAndFooter: {
    moreGames: "Mais jogos",
    playSudoku: "Jogue Sudoku",
    playSolitaire: "Jogue paciência",
    playMahjong: "Jogar Mahjong",
  },

  blog: "Blog",
  dailyChallenges: "Desafios diários",
  dailyChallenge: "DESAFIO DIÁRIO",
  readBlog: "Ler Blog",
  sudokuPuzzleGame: "Sudoku Pro — Jogo de puzzle",
  sudokuTipsAndTrick: "Dicas e truques de Sudoku — De Noob a SudokuPro",
  oops: "Ooops...",
  errorPageNotFound: "404 erro. Página não encontrada",
  sorryGoBackHome: "Lamentamos, a página que pediu não pôde ser encontrada. Por favor, volte à página inicial.",
  backToHomepage: "VOLTAR À HOMEPAGE",
  cookieText:
    "O nosso website utiliza cookies para personalizar o conteúdo e para analisar o nosso tráfego, o que também pode resultar em perfis.",
  ok: "OK",
  privacyPolicy: "Política de Privacidade",
  howToPlay: "Como jogar",
  undo: "Desfazer",
  notes: "Observações",
  hints: "Dicas",
  erase: "Apagar",
  newGame: "novoGame",
  level: "Nível: ",
  levels: "Níveis",
  similarArticles: "Artigos semelhantes",
  playSudokuOnline: "Jogar Sudoku Online",
  startYourBrainTraining:
    "Comece já a treinar o seu cérebro, descarregue o jogo online gratuito Sudoku Pro, e não se queixe que não consegue parar de resolver.",
  nowDownload: "AGORA DESCARREGAR AGORA DESCARREGAR AGORA DESCARREGAR AGORA DESCARREGAR",
  playBest: "Jogue melhor",
  online: "On-line",
  playSudoku: "Sudoku!",
  seeWeToldYou: "Vê, nós dissemos-lhe que as regras são fáceis!",
  dontWasteYourTime: "Agora, não perca tempo a duvidar, ",
  appIsSudokuPro: "a melhor aplicação de sudoku é o Sudoku Pro, e deve experimentá-lo!",
  goBack: "Voltar atrás",
  points: "pontos",
  yes: "Sim",
  no: "Não",
  playMahjong: "Jogue Mahjong Online",
  siteMap: "Mapa do site",
  otherGame: "Outros jogos",
  historySudoku: "História do Sudoku",
  rulesSudoku: "Regras do Sudoku",
  tipsSudoku: "Dicas para Sudoku",
  //   /////Win poup////
  youWin: "Está a ganhar!",
  youWinText: "Baixe o app para jogar a qualquer hora e sem restrições",
  followUs: "Siga-nos nas Redes Sociais:",
  winLevel: "Nível",
  startNewGame: "Iniciar Novo Jogo",
  time: "Hora",
  bestResult: "Melhor Resultado",
  //   ////////////////////////
  playOnline: "jogar online",
  cancel: "Anular",
  //   //////// Meta and Description /////////
  metaPageTitle: "Como Jogar Sudoku — De Noob a SudokuPro",
  metaPageDescription:
    "Leia o blogue SudokuPro para aprender todas as dicas sudoku e truques para regra do Sudoku jogar online, independentemente de ser um principiante absoluto ou um jogador avançado. Obtenha uma explicação fácil das estratégias profissionais para jogar mais eficazmente e aumentar a saúde do seu cérebro",
  metaBlogTitle: "Dicas e truques de Sudoku — De Noob a SudokuPro",
  metaBlogDescription:
    "Leia o nosso blogue para aprender todos os truques em Sudoku online grátis para jogar para principiantes e jogadores experientes. Obtenha uma explicação fácil das estratégias profissionais e dicas para resolver o Sudoku completo.",
  //   //////Basic rules///////
  gameRules: {
    subtitle: "Sudoku tornado fácil",
    title: "Regras básicas do Sudoku",
    titleText:
      "Se é um novato no mundo do Sudoku, não há nada de que se envergonhar. Este jogo é muito lógico e fácil de obter. Existem 3 regras básicas e, dependendo do tipo que escolher, poderá haver alterações, no entanto, as regras básicas estabelecidas raramente mudam.",
    goal: "Objectivo:",
    goalText:
      "Preenchendo uma grelha 9×9, de modo a que cada coluna, linha, e secção 3×3 contenha os dígitos entre 1 a 9. Tradicionalmente não há limite de tempo, mas alguns jogos de Sudoku para o cérebro acrescentam limitações de tempo que levam a pensar mais rapidamente.",
    ruleTitle: "Como jogar Sudoku?",
    ruleText:
      "Quando se começa, alguns quadrados da grelha 9×9 já terão dígitos. A sua tarefa é utilizar a sua inteligência para preencher os pontos em falta e completar a grelha.",
    fail: "Se falhar:",
    list: "Qualquer linha tem dois números iguais <list> Qualquer coluna tem dois números iguais <list> Qualquer grade 3×3 tem dois números iguais",
    remember: "Lembre-se:",
    rememberText: "Cada dígito de 1 a 9 pode ser usado apenas uma vez em linha, coluna, ou grelha 3×3",
  },
  playNow: "JOGUE AGORA",
  //   /////////// Months ////////
  January: "Janeiro",
  February: "Fevereiro",
  March: "Março",
  April: "Abril",
  May: "Maio",
  June: "Junho",
  July: "Julho",
  August: "Agosto",
  September: "Setembro",
  October: "Outubro",
  November: "Novembro",
  December: "Dezembro",
  //////////// Difficulty  ////////
  EASY: "Fácil",
  MEDIUM: "Médio",
  HARD: "Difícil",
  EXPERT: "Perito",
  EXTREME: "Extremo",

  //   /////////NoGame RC//////
  sorry: "desculpe",
  gameNotFount: "jogo não encontrado",
  chooseChallenge: "Escolha um desafio aqui:",
};

export const en: any = {
  ISOcode: "en",
  countryName: "Great Britain",
  language: "English",

  headerAndFooter: {
    moreGames: "More Games",
    playSudoku: "Play Sudoku",
    playSolitaire: "Play Solitaire",
    playMahjong: "Play Mahjong",
  },

  blog: "Blog",
  dailyChallenges: "Daily Challenges",
  dailyChallenge: "DAILY CHALLENGE",
  readBlog: "Read Blog",
  omisoft: "OmiSoft",
  sudokuPuzzleGame: "Sudoku Pro - Puzzle Game",
  sudokuTipsAndTrick: "Sudoku Tips And Tricks — From Noob To SudokuPro",
  oops: "Oops...",
  errorPageNotFound: "404 error. Page not found",
  sorryGoBackHome: "We're sorry, the page you requested could not be found. Please go back to the homepage.",
  backToHomepage: "BACK TO HOMEPAGE",
  cookieText:
    "Our website uses cookies to personalize content and to analyze our traffic which may also result in profiling.",
  ok: "OK",
  privacyPolicy: "Privacy Policy",
  howToPlay: "How to play",
  undo: "Undo",
  notes: "Notes",
  hints: "Hints",
  erase: "Erase",
  newGame: "New Game",
  level: "Level: ",
  levels: "Levels",
  similarArticles: "Similar Articles",
  playSudokuOnline: "Play Sudoku Online",
  startYourBrainTraining:
    "Start your brain training now, download the free online game Sudoku Pro, and don’t complain that you can’t stop solving.",
  nowDownload: "DOWNLOAD NOW DOWNLOAD NOW DOWNLOAD NOW DOWNLOAD NOW DOWNLOAD NOW",
  playBest: "Play best",
  online: "Online",
  playSudoku: "Sudoku!",
  seeWeToldYou: "See, we told you that rules are easy!",
  dontWasteYourTime: "Now, don't waste your time doubting, the best sudoku",
  appIsSudokuPro: "app is Sudoku Pro, and you must try it!",
  goBack: "Go back",
  facebook: "Facebook",
  twitter: "Twitter",
  telegram: "Telegram",
  points: "points",
  yes: "Yes",
  no: "No",
  playMahjong: "Play Mahjong Online",
  siteMap: "Site map",
  otherGame: "Other game",
  historySudoku: "Sudoku History",
  rulesSudoku: "Rules for Sudoku",
  tipsSudoku: "Tips for Sudoku",

  /////Win poup////
  youWin: "You won!",
  youWinText: "Download the app to play anytime and without any restrictions",
  followUs: "Follow Us in Social Media:",
  winLevel: "Level",
  startNewGame: "Start New Game",
  time: "Time",
  bestResult: "Best Result",
  ////////////////////////
  playOnline: "play online",
  cancel: "Cancel",
  //////// Meta and Description /////////
  metaPageTitle: "How To Play Sudoku — From Noob To SudokuPro",
  metaPageDescription:
    "Read the SudokuPro blog to learn all tips and tricks of playing Sudoku regardless if you are an absolute beginner or an advanced player. Get an Easy explanation of pro strategies to play more effectively and boost your brain health",

  metaBlogTitle: "Sudoku Tips And Tricks — From Noob To SudokuPro",
  metaBlogDescription:
    "Read our blog to learn all tricks in Sudoku for beginners and expert players. Get an easy explanation of pro strategies and tips for solving Sudoku",

  //////Basic rules///////

  gameRules: {
    subtitle: "Sudoku made easy",
    title: "Basic rules of Sudoku",
    titleText:
      "If you are a newbie to the Sudoku world, there is nothing to be ashamed about. This game is very logical and easy to get. There are 3 basic rules and depending on the type you choose there might be alterations, however, the basic rules set rarely change.",
    goal: "Goal:",
    goalText:
      "Filling in a 9×9 grid, so that each column, row, and 3×3 section contain the digits between 1 to 9. Traditionally there is no time limit, but some Sudoku games for the brain add time limitations that push to think quicker.",
    ruleTitle: "How to play Sudoku for beginners?",
    ruleText:
      "When you start, some squares of the 9×9 grid will already have digits. Your task is to use your brainpower to fill in the missing spots and complete the grid.",
    fail: "You fail if:",
    list: "Any row has two same numbers <list> Any column has two same numbers <list> Any 3×3 grid has two same numbers",
    remember: "Remember:",
    rememberText: "Each digit from 1 to 9 can be used just once in a row, column, or 3×3 grid",
  },

  playNow: "PLAY NOW",
  /////////// Months ////////
  January: "January",
  February: "February",
  March: "March",
  April: "April",
  May: "May",
  June: "June",
  July: "July",
  August: "August",
  September: "September",
  October: "October",
  November: "November",
  December: "December",
  ///////// Game levels //////
  EASY: "Easy",
  MEDIUM: "Medium",
  HARD: "Hard",
  EXPERT: "Expert",
  EXTREME: "Extreme",
  /////////NoGame RC//////
  sorry: "sorry",
  gameNotFount: "game not found",
  chooseChallenge: "Choose a challenge here:",

  // Login Page
  loginMetaTitle: "Login to Sudoku Pro",
  loginMetaDescription: "Log in to save your game progress and compete with other players",
  logIn: "Log In",
  welcomeBack: "Welcome Back",
  continueWithGoogle: "Log in with Google",
  continueWithFacebook: "Log in with Facebook",
  authInfoModalTitleFailed: "Authorization failed",
  authorizationFailedMessage: "Authorization failed, try again later",

  // Registration Page
  registrationMetaTitle: "Login to Sudoku Pro",
  registrationMetaDescription:
    "There is only one small step left to complete the registration and get the most out of the Sudoku game",
  createAnAccount: "Create an Account",
  nickName: "Nickname",
  country: "Country",
  signUp: "Sign Up",
  createYourNickName: "Create your nickname",
  authInfoModalRegistrationTitleErrors: "Registration failed",
  registrationFailedMessage: "Registration failed, try again later",

  // Logout Dialog
  logOutMessage: "Are you sure you want to log out? Your progress will not be saved",
  logOut: "Log Out",

  // Awards Page
  awardsMetaTitle: "Awards | Sudoku Web",
  awardsMetaDescription: "Complete the daily challenges in Sudoku Pro and collect all the rewards for the year!",
  awards: "Awards",
  yourAwards: "Your Awards",
  yourAwardsDescription: "Complete daily challenges and get rewards at the end of each month!",
  awardsDontMissASingleDay: "Don't miss a single day - play sudoku!",
  getAwards: "Get awards!",

  // Monthly Award Dialog
  awardDialogTitle: "Monthly Award received",
  awardDialogDescription: "You have completed all daily challenges for a month and received an award! Congratulations!",
  awardShare: "Share",

  // Authorization banner
  authorizationBannerMessage: "Log in to save your progress and awards 🏆",

  // You Won Dialog
  levelsLeftMessage: "🏆Levels left to get the reward: %s",
  finalPoints: "Final points",

  // Ratings Block
  topCountriesToday: "TOP 20 Countries Today",
  topUsersToday: "TOP 100 Users Today",
  numberOfUsers: "Number of Users",
  updatedEvery24hours: "Updated every 24 hours",
  experiencePoints: "Experience points",
  showFullList: "Show full list",
  showShortList: "Show a short list",

  // ControlHintsModalWarning
  controlHintsModalWarningMessage: "This feature is not available in daily challenges",

  // ConsecutiveDaysWarningModal
  consecutiveDaysModalText: "Number of days of continuous play. Play every day to improve this achievement!",
  currentConsecutiveDaysModalText: "Current result:",
  maxConsecutiveDaysModalText: "The best result:",
  consecutiveDaysModalTextLogIn: "Sign in to save your achievements",
};

import { useState } from "react";
import { useRouter } from "next/router";
import useTranslation, { useRoutes } from "../../helpers/translationTexts/useTranslation";

import Container from "../Container";
import MobileMenu from "./MobileMenu";
import { HeaderComponent } from "./Header.components";
import { useSession } from "../../context/SessionProvider";
import syncManager from "../../api/SyncManager";
import pointsManager from "../../api/PointsManager";
import HeaderContent from "./HeaderContent/HeaderContent";
import useWrapsTextParentheses from "@/utils/wrapsTextParentheses";
import { AuthInfoModal, ConsecutiveDaysWargingModal } from "../modal";
import GameChangeTab from "../GameChangeTab";

const Header: React.FC = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isOpenAuthInfoModal, setIsOpenAuthInfoModal] = useState<boolean>(false);
  const [isOpenConsencutiveModal, setIsOpenConsencutiveModal] = useState<boolean>(false);
  const { annualAwardsCount, userData, setUserData, shockModeData, setShockModeData, setUserPoints, dailyProgress } =
    useSession();
  const path = useRouter().asPath;
  const langPath = path.split("/")[1];
  const lang = langPath.length > 2 ? "" : langPath;

  const homePath = useRoutes().Root;

  const dailyChallengesText = useTranslation("dailyChallenges");
  const headerStrings = useTranslation("headerAndFooter");

  const dailyProgressText = useWrapsTextParentheses(dailyProgress);
  const annualAwardsCountText = useWrapsTextParentheses(`${annualAwardsCount}/12`);
  const consecutiveDaysText = useWrapsTextParentheses(shockModeData?.consecutiveDays ?? 0);

  const logOut = useTranslation("logOut");
  const logOutMessage = useTranslation("logOutMessage");
  const cancel = useTranslation("cancel");
  const playMahjongText = headerStrings.playMahjong;
  const playSudokuText = headerStrings.playSudoku;
  const moreGamesText = headerStrings.moreGames;

  const dailySudokuRoute = useRoutes().DailySudoku;
  const awardsRoute = useRoutes().Awards;

  const handleOpenConsecutiveModal = () => setIsOpenConsencutiveModal(true);

  const handleLogOutClicked = () => {
    syncManager.deleteSyncAwardsData(userData?._id);
    syncManager.clearUserData();
    pointsManager.remove();
    setUserPoints(0);
    setShockModeData(null);
    setUserData(null);
    setIsOpenAuthInfoModal(false);
    setIsMobileMenuOpen(false);
  };

  const handleCloseAuthInfoCliked = () => {
    setIsOpenAuthInfoModal(false);
  };

  const handleCloseMobileMenu = () => setIsMobileMenuOpen(false);

  return (
    <>
      <GameChangeTab
        playMahjongText={playMahjongText}
        handleCloseMobileMenu={handleCloseMobileMenu}
        lang={lang}
        playSudokuText={playSudokuText}
        moreGamesText={moreGamesText}
        homePath={homePath}
      />
      <HeaderComponent>
        <Container>
          <HeaderContent
            isMobileMenuOpen={isMobileMenuOpen}
            annualAwardsCount={annualAwardsCountText}
            consecutiveDays={consecutiveDaysText}
            dailySudokuRoute={dailySudokuRoute}
            awardsRoute={awardsRoute}
            handleOpenConsecutiveModal={handleOpenConsecutiveModal}
            path={path}
            setIsMobileMenuOpen={setIsMobileMenuOpen}
            dailyChallengesText={dailyChallengesText}
            setIsOpenAuthInfoModal={setIsOpenAuthInfoModal}
            dailyProgress={dailyProgressText}
          />
        </Container>

        <MobileMenu
          isMenuOpen={isMobileMenuOpen}
          setMenuOpen={setIsMobileMenuOpen}
          setIsOpenAuthInfoModal={setIsOpenAuthInfoModal}
          openConsencutiveModal={() => setIsOpenConsencutiveModal(true)}
          shockModeData={shockModeData}
          annualAwardsCount={annualAwardsCount}
          currentPath={path}
          dailyProgress={dailyProgress}
        />

        <ConsecutiveDaysWargingModal
          currentPath={path}
          isOpen={isOpenConsencutiveModal}
          onCloseClicked={() => setIsOpenConsencutiveModal(false)}
          closeMobileMenu={() => setIsMobileMenuOpen(false)}
        />

        <AuthInfoModal
          isOpen={isOpenAuthInfoModal}
          onLogoutClicked={handleLogOutClicked}
          title={logOut}
          description={logOutMessage}
          onCloseClicked={handleCloseAuthInfoCliked}
          btnNameCancel={cancel}
        />
      </HeaderComponent>
    </>
  );
};

export default Header;

import Container from "../Container";
import useMediaQuery from "@/utils/useMediaQuery";
import { BuyUsACoffee } from "../common";
import { LogoTab, MahjongTab, MoreGamesTab, SudokuProTab, TabsWrapper, Wrapper } from "./GameChangeTab.components";
import { GameChangeTabProps } from "./types";
import { TABLET_MEDIA_QUERY } from "@/constants/breakpoints";

const GameChangeTab: React.FC<GameChangeTabProps> = ({
  handleCloseMobileMenu,
  playSudokuText,
  lang,
  playMahjongText,
  moreGamesText,
  homePath,
}) => {
  const isTablet = useMediaQuery(TABLET_MEDIA_QUERY);

  return (
    <Container>
      <Wrapper>
        <TabsWrapper>
          <LogoTab onClick={handleCloseMobileMenu} />

          <SudokuProTab title={playSudokuText} homePath={homePath} onClick={handleCloseMobileMenu} />
          <MahjongTab title={playMahjongText} lang={lang} />
          <MoreGamesTab title={moreGamesText} />
        </TabsWrapper>

        {isTablet ? <BuyUsACoffee section="header" /> : null}
      </Wrapper>
    </Container>
  );
};

export default GameChangeTab;

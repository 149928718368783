export const sr: any = {
  ISOcode: "sr",
  countryName: "Србија",
  language: "Српски",

  headerAndFooter: {
    moreGames: "Више игара",
    playSudoku: "Играјте Судоку",
    playSolitaire: "Играјте Солитаире",
    playMahjong: "Играј Маџонг",
  },

  blog: "Блог",
  dailyChallenges: "Свакодневни изазови",
  dailyChallenge: "ДНЕВНИ ИЗАЗОВ",
  readBlog: "Прочитајте блог",
  omisoft: "OmiSoft",
  sudokuPuzzleGame: "Sudoku Pro  — Пуззле игра",
  sudokuTipsAndTrick: "Судоку на мрежи - савети и трикови, или како постати професионалац",
  oops: "Упс...",
  errorPageNotFound: "404 грешка. Страница није пронађена",
  sorryGoBackHome: "Жао нам је, страница коју сте тражили није пронађена. Вратите се на почетну страницу.",
  backToHomepage: "ПОВРАТАК НА НАСЛОВНУ СТРАНИЦУ",
  cookieText:
    "Ако наставите да претражујете сајт, потврђујете да сте прочитали Политику приватности сајта и да се слажете са употребом колачића.",
  ok: "У реду",
  privacyPolicy: "Правила о приватности",
  howToPlay: "Како играти",
  undo: "Поништити",
  notes: "Напомене",
  hints: "Савети",
  erase: "Обриши",
  newGame: "НОВА ИГРА",
  level: "Ниво: ",
  levels: "Нивои",
  similarArticles: "Слични чланци",
  playSudokuOnline: "Играјте судоку на мрежи",
  startYourBrainTraining:
    "Почните да развијате свој мозак одмах! Преузмите бесплатну Судоку Про онлине игру и престаните да се жалите да не можете престати да решавате Судоку загонетке.",
  nowDownload:
    "САДА ПРЕУЗМИ САДА ПРЕУЗМИ САДА ПРЕУЗМИ САДА ПРЕУЗМИ САДА ПРЕУЗМИ САДА ПРЕУЗМИ САДА ПРЕУЗМИ САДА ПРЕУЗМИ САДА ПРЕУЗМИ САДА",
  playBest: "Играјте најбоље",
  online: "Онлине",
  playSudoku: "Судоку!",
  seeWeToldYou: "Видите, рекли смо вам да су правила лака!",
  dontWasteYourTime: "Сада не губите време на сумње, најбоља апликација ",
  appIsSudokuPro: "за игру судоку је Sudoku Pro и морате је пробати!",
  goBack: "Вратити се",
  facebook: "Фејсбук",
  twitter: "Твиттер",
  telegram: "Телеграм",
  points: "бодова",
  yes: "да",
  no: "Не",
  playMahjong: "Играјте Махјонг на мрежи",
  siteMap: "Мапа сајта",
  otherGame: "Друге игре",
  historySudoku: "Судоку Хистори",
  rulesSudoku: "Правила за Судоку",
  tipsSudoku: "Савети за Судоку",
  /////Win poup////
  youWin: "Победа!",
  youWinText: "Преузмите апликацију да бисте играли било када без ограничења",
  followUs: "Пратите нас на друштвеним мрежама:",
  winLevel: "Ниво",
  startNewGame: "Започните нову игру",
  time: "Време",
  bestResult: "Бољи резултат",
  ////////////////////////
  playOnline: "играти на мрежи",
  cancel: "Поништити",
  //////// Meta and Description /////////

  metaPageTitle: "Како играти Судоку — од лаика до Судоку Про",
  metaPageDescription:
    "Прочитајте блог Sudoku Pro да бисте добили савете и напутке о томе како играти Судоку, независно од вашег тренутног нивоа. Добијте једноставна објашњења компликованих стратегија ове логичке игре да бисте играли ефикасније и унапређивали своју меморију.",

  metaBlogTitle: "Судоку Онлине - савети и трикови | Од почетника до Судоку Про",
  metaBlogDescription:
    "Читајте наш Sudoku Pro блог да бисте сазнали о свим посебностима Судоку за почетнике и искусне играче. Добијте једноставна објашњења професионалних стратегија и савета за решавање Судоку онлајн.",

  //////Basic rules///////
  gameRules: {
    subtitle: "Судоку - је једноставан!",
    title: "Основна правила судокуа",
    titleText:
      "Ако сте почетник и не знате како решавати судоку, нема потребе да се стидите. Ова игра је веома логична и учење како играти судоку је врло једноставно. Постоје 3 основна правила, и у зависности од врсте логичке игре коју сте изабрали могу постојати неки додатни аспекти, али основна правила судоку игре остају непромењена.",
    goal: "Циљ:",
    goalText:
      "Попуните 9x9 мрежу тако да свака колона, ред и 3x3 секција садрже бројеве од 1 до 9. Традиционално, нема ограничења времена, али неки судоку онлајн додатно имају временско ограничење и изгледају као да подстичу брже размишљање.",
    ruleTitle: "Како играти судоку?",
    ruleText:
      "Када почнете, неки квадрати у мрежи 9x9 већ ће садржати бројеве. Ваше задатак је да попуните празна места тако да не остане ниједно празно место.",
    fail: "Не успевате ако:",
    list: "Било који ред садржи два иста броја. <list> Било која колона има два иста броја. <list> Било која секција 3x3 има два иста броја.",
    remember: "Запамтити:",
    rememberText: "Сваки број од 1 до 9 може се користити само једном у реду, колони или секцији 3x3.",
  },
  playNow: "ИГРАЈ",
  /////////// Months ////////
  January: "Јануар",
  February: "Фебруар",
  March: "Март",
  April: "Април",
  May: "Мај",
  June: "Јун",
  July: "Јул",
  August: "Август",
  September: "Септембар",
  October: "Октобар",
  November: "Новембар",
  December: "Децембар",
  ///////// Game levels //////
  EASY: "Лако",
  MEDIUM: "Средње",
  HARD: "Тешко",
  EXPERT: "Стручни",
  EXTREME: "Екстремно",
  /////////NoGame RC//////
  sorry: "извињавам се",
  gameNotFount: "игра није пронађена",
  chooseChallenge: "Изаберите изазов овде:",

  // Login Page
  loginMetaTitle: "Пријавите се на Судоку Про",
  loginMetaDescription: "Пријавите се да бисте сачували напредак игре и такмичили се са другим играчима",
  logIn: "Пријавите се",
  welcomeBack: "Добродошли назад",
  continueWithGoogle: "Наставите са Гоогле-ом",
  continueWithFacebook: "Наставите са Фејсбуком",
  authorizationFailedMessage: "Ауторизација није успела, покушајте поново касније",

  // Registration Page
  registrationMetaTitle: "Заврши регистрацију - Судоку Про",
  registrationMetaDescription:
    "Остао је само један мали корак да завршите регистрацију и извучете максимум из игре Судоку",
  createAnAccount: "Направи налог",
  nickName: "Надимак",
  country: "Држава",
  signUp: "Пријави се",
  createYourNickName: "Направите свој надимак",
  registrationFailedMessage: "Регистрација није успела, покушајте поново касније",

  // Logout Dialog
  logOutMessage: "Да ли сте сигурни да желите да се одјавите? Ваш напредак неће бити сачуван",
  logOut: "Одјавити се",

  // Awards Page
  awardsMetaTitle: "Ваше награде - Судоку Про",
  awardsMetaDescription: "Довршите свакодневне изазове у Судоку Про и сакупите све награде за годину!",
  awards: "Награде",
  yourAwards: "Ваше награде",
  yourAwardsDescription: "Завршите дневне изазове и добијте награде на крају сваког месеца!",
  awardsDontMissASingleDay: "Не пропустите ниједан дан - играјте судоку!",

  // Monthly Award Dialog
  awardDialogTitle: "Добијена месечна награда",
  awardDialogDescription: "Одрадили сте све дневне изазове за месец дана и добили награду! Честитам!",
  awardShare: "Објави",

  // Authorization banner
  authorizationBannerMessage: "Пријавите се да бисте сачували свој напредак и награде 🏆",

  // You Won Dialog
  levelsLeftMessage: "Преостали нивои за добијање награде: %s",

  // Ratings Block
  topCountriesToday: "ТОП 20 земаља данас",
  topUsersToday: "ТОП 100 корисника данас",
  numberOfUsers: "Број корисника",
  updatedEvery24hours: "Ажурира се свака 24 сата",
  experiencePoints: "Искуствени бодови",
  showFullList: "Прикажи пуну листу",
  showShortList: "Прикажи кратку листу",
};

export const hr: any = {
  ISOcode: "hr",
  countryName: "Hrvatska",
  language: "Hrvatski",

  headerAndFooter: {
    moreGames: "Više igara",
    playSudoku: "Igrajte Sudoku",
    playSolitaire: "Igrajte Solitaire",
    playMahjong: "Igrajte Mahjong",
  },

  blog: "Blog",
  dailyChallenges: "Dnevni izazov",
  dailyChallenge: "DNEVNI IZAZOV",
  readBlog: "Pročitajte blog",
  omisoft: "OmiSoft",
  sudokuPuzzleGame: "Sudoku Pro - slagalica igra",
  sudokuTipsAndTrick: "Sudoku online - savjeti i trikovi, ili kako postati profesionalac",
  oops: "ups...",
  errorPageNotFound: "404 pogreška. Stranica nije pronađena",
  sorryGoBackHome: "Nažalost, stranicu koju ste tražili nije moguće pronaći. Molimo, vratite se na početnu stranicu.",
  backToHomepage: "NATRAG NA POČETNU STRANICU",
  cookieText:
    "Nastavkom pregledavanja web stranice potvrđujete da ste pročitali Pravila o privatnosti web stranice i pristajete na korištenje kolačića.",
  ok: "OK",
  privacyPolicy: "Politika privatnosti",
  howToPlay: "Kako igrati",
  undo: "Otkazati",
  notes: "Bilješke",
  hints: "Savjeti",
  erase: "Izbriši",
  newGame: "NOVA IGRA",
  level: "Razina: ",
  levels: "Razine",
  similarArticles: "Slični članci",
  playSudokuOnline: "Igrajte sudoku online",
  startYourBrainTraining:
    "Počnite razvijati svoj mozak odmah! Preuzmite besplatnu online igru Sudoku Pro i nemojte se žaliti da ne možete prestati rješavati sudoku zagonetke.",
  nowDownload: "PREUZMITE SADA PREUZMITE SADA PREUZMITE SADA PREUZMITE SADA PREUZMITE SADA PREUZMITE SADA",
  playBest: "Igrajte najbolje",
  online: "Na liniji",
  playSudoku: "Sudoku!",
  seeWeToldYou: "Vidite, rekli smo da su pravila jednostavna!",
  dontWasteYourTime: "Sada ne gubite vrijeme na sumnje, najbolja aplikacija",
  appIsSudokuPro: "za igranje sudoku je Sudoku Pro i trebate je isprobati!",
  goBack: "Idi natrag",
  facebook: "Facebook",
  twitter: "Cvrkut",
  telegram: "Telegram",
  points: "bodova",
  yes: "Da",
  no: "Ne",
  playMahjong: "Igrajte Mahjong online",
  siteMap: "Karta stranice",
  otherGame: "Ostale igrice",
  historySudoku: "Sudoku povijest",
  rulesSudoku: "Pravila za Sudoku",
  tipsSudoku: "Savjeti za Sudoku",
  /////Win poup////
  youWin: "Pobjeda!",
  youWinText: "Preuzmite aplikaciju za igranje bilo kada bez ograničenja",
  followUs: "Pratite nas na društvenim mrežama:",
  winLevel: "Razina",
  startNewGame: "Pokreni novu igru",
  time: "Vrijeme",
  bestResult: "Bolji rezultat",
  ////////////////////////
  playOnline: "igrati online",
  cancel: "Otkazati",
  //////// Meta and Description /////////
  metaPageTitle: "Kako igrati Sudoku — od laika do Sudoku Pro",
  metaPageDescription:
    "Pročitajte Sudoku Pro blog kako biste dobili savjete i upute o tome kako igrati Sudoku, bez obzira na trenutnu razinu. Dobijte jednostavna objašnjenja složenih strategija ove logičke igre kako biste igrali učinkovitije i poboljšali svoje pamćenje.",

  metaBlogTitle: "Sudoku Online - savjeti i trikovi | Od početnika do Sudoku Pro",
  metaBlogDescription:
    "Pročitajte naš Sudoku Pro blog kako biste saznali sve pojedinosti o Sudoku-u za početnike i iskusne igrače. Dobijte jednostavna objašnjenja profesionalnih strategija i savjete za rješavanje Sudoku-a online.",

  //////Basic rules///////

  gameRules: {
    subtitle: "Sudoku je jednostavan!",
    title: "Osnovna pravila Sudoku igre",
    titleText:
      "Ako ste početnik i ne znate kako rješavati sudoku, nema čega se sramiti. Ova igra je vrlo logična i naučiti kako igrati sudoku je vrlo jednostavno. Postoje 3 osnovna pravila, i ovisno o odabranom tipu logičke igre mogu postojati određeni nijanse, ali osnovna pravila igre sudoku ostaju ista.",
    goal: "Cilj:",
    goalText:
      "Ispunite mrežu veličine 9x9 tako da svaki stupac, redak i 3x3 sekcija sadrže brojeve od 1 do 9. Tradicionalno, nema vremenskih ograničenja, ali neka online sudoku mogu imati vremenska ograničenja i navodno potiču brže razmišljanje.",
    ruleTitle: "Kako igrati Sudoku?",
    ruleText:
      "Kada počnete, neki kvadrati u mreži 9x9 već će imati brojeve. Vaš zadatak je popuniti prazna polja, ne ostavljajući nijedno prazno mjesto.",
    fail: "Ne uspijevate ako:",
    list: "Bilo koji redak ima dva ista broja. <list> Bilo koji stupac ima dva ista broja. <list> Bilo koja sekcija 3x3 ima dva ista broja.",
    remember: "ZAPAMTITI:",
    rememberText: "Svaki broj od 1 do 9 može se koristiti samo jednom u retku, stupcu ili odjeljku 3×3",
  },

  playNow: "Igraj sada",
  /////////// Months ////////
  January: "Siječanj",
  February: "Veljača",
  March: "Ožujak",
  April: "Travanj",
  May: "Svibanj",
  June: "Lipanj",
  July: "Srpanj",
  August: "Kolovoz",
  September: "Rujan",
  October: "Listopad",
  November: "Studeni",
  December: "Prosinac",
  ///////// Game levels //////
  EASY: "Svjetlo",
  MEDIUM: "Prosjek",
  HARD: "Teško",
  EXPERT: "Stručnjak",
  EXTREME: "Ekstremno",
  /////////NoGame RC//////
  sorry: "Oprosti",
  gameNotFount: "Igra nije pronađena",
  chooseChallenge: "Odaberite izazov ovdje:",

  // Login Page
  loginMetaTitle: "Prijavite se na Sudoku Pro",
  loginMetaDescription: "Prijavite se kako biste spremili svoj napredak u igri i natjecali se s drugim igračima",
  logIn: "Prijaviti se",
  welcomeBack: "Dobrodošao natrag",
  continueWithGoogle: "Nastavite s Googleom",
  continueWithFacebook: "Nastavite s Facebookom",
  authorizationFailedMessage: "Podijelite autorizaciju, pokušajte kasnije",

  // Registration Page
  registrationMetaTitle: "Završi registraciju - Sudoku Pro",
  registrationMetaDescription:
    "Ostao je još samo jedan mali korak da dovršite registraciju i izvučete maksimum iz Sudoku igre",
  createAnAccount: "Stvorite račun",
  nickName: "Nadimak",
  country: "Zemlja",
  signUp: "Prijavite se",
  createYourNickName: "Stvorite svoj nadimak",
  registrationFailedMessage: "Registracija nije uspjela, pokušajte ponovno kasnije",

  // Logout Dialog
  logOutMessage: "Jeste li sigurni da se želite odjaviti? Vaš napredak neće biti spremljen",
  logOut: "Odjavite se",

  // Awards Page
  awardsMetaTitle: "Vaše nagrade - Sudoku Pro",
  awardsMetaDescription: "Dovršite dnevne izazove u Sudoku Pro i sakupite sve nagrade za godinu!",
  awards: "Nagrade",
  yourAwards: "Vaše nagrade",
  yourAwardsDescription: "Ispunite dnevne izazove i osvojite nagrade na kraju svakog mjeseca!",
  awardsDontMissASingleDay: "Ne propustite niti jedan dan - igrajte sudoku!",

  // Monthly Award Dialog
  awardDialogTitle: "Dobivena mjesečna nagrada",
  awardDialogDescription: "Završili ste sve dnevne izazove za mjesec dana i dobili ste nagradu! Čestitamo!",
  awardShare: "Udio",

  // Authorization banner
  authorizationBannerMessage: "Prijavite se da biste spremili svoj napredak i nagrade 🏆",

  // You Won Dialog
  levelsLeftMessage: "Ostale razine za dobivanje nagrade: %s",

  // Ratings Block
  topCountriesToday: "TOP 20 zemalja danas",
  topUsersToday: "TOP 100 korisnika danas",
  numberOfUsers: "Broj korisnika",
  updatedEvery24hours: "Ažurira se svaka 24 sata",
  experiencePoints: "Bodovi iskustva",
  showFullList: "Prikaži cijeli popis",
  showShortList: "Prikaži kratki popis",
};

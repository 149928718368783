export const sq: any = {
  ISOcode: "sq",
  countryName: "Shqipëria",
  language: "Gjuha shqipe",

  headerAndFooter: {
    moreGames: "Më shumë lojëra",
    playSudoku: "Luaj Sudoku",
    playSolitaire: "Luaj Solitaire",
    playMahjong: "Luaj Mahjong",
  },

  blog: "Blog",
  dailyChallenges: "Një sfidë e përditshme",
  dailyChallenge: "SFIDË E PËRDITSHME",
  readBlog: "Lexoni blogun",
  omisoft: "OmiSoft",
  sudokuPuzzleGame: "Sudoku Pro është një lojë puzzle",
  sudokuTipsAndTrick: "Sudoku në internet - këshilla dhe truket, ose si të bëheni profesionist",
  oops: "Oops...",
  errorPageNotFound: "Gabim 404. Faqja nuk u gjet",
  sorryGoBackHome: "Na vjen keq, faqja që kërkuat nuk mund të gjendej. Ju lutemi kthehuni në faqen kryesore.",
  backToHomepage: "KTHIMI NË FAQEN KRYESORE",
  cookieText:
    "Në vazhdim të shfletimit të faqes, ju konfirmoni se keni lexuar Politikën e privatësisë të faqes dhe pajtoheni me përdorimin e cookies.",
  ok: "OK",
  privacyPolicy: "Politika e privatësisë",
  howToPlay: "Si të luani",
  undo: "Zhbër",
  notes: "Shënime",
  hints: "Këshilla",
  erase: "Fshije",
  newGame: "Lojë e re",
  level: "Niveli: ",
  levels: "Nivelet",
  similarArticles: "Artikuj të ngjashëm",
  playSudokuOnline: "Luaj sudoku online",
  startYourBrainTraining:
    "Fillo t'i zhvillosh trurin tënd tani! Shkarko lojën falas në internet Sudoku Pro dhe mos ankohu se nuk mund të ndalosh zgjidhjen e rombllomave sudoku.",
  nowDownload: "SHKARKO TANI SHKARKO TANI SHKARKO TANI SHKARKO TANI SHKARKO TANI SHKARKO TANI",
  playBest: "Luaj më së miri",
  online: "Online",
  playSudoku: "Sudoku!",
  seeWeToldYou: "Shikoni, ju thamë se rregullat janë të lehta!",
  dontWasteYourTime: "Tani mos humbni kohë në dyshime, aplikacioni më i mirë",
  appIsSudokuPro: "për lojën e sudoku është Sudoku Pro dhe duhet ta provoni atë!",
  goBack: "Kthehu mbrapa",
  facebook: "Facebook",
  twitter: "Cicëroj",
  telegram: "Telegrami",
  points: "pikë",
  yes: "po",
  no: "Nr",
  playMahjong: "Luaj Mahjong Online",
  siteMap: "Harta e faqes",
  otherGame: "Lojëra të tjera",
  historySudoku: "Historia e Sudokut",
  rulesSudoku: "Rregullat për Sudoku",
  tipsSudoku: "Këshilla për Sudoku",
  /////Win poup////
  youWin: "Ju jeni fitues!",
  youWinText: "Shkarkoni aplikacionin për të luajtur në çdo kohë pa kufi.",
  followUs: "Na ndiqni në rrjetet sociale:",
  winLevel: "Niveli",
  startNewGame: "Filloni një lojë të re",
  time: "Koha",
  bestResult: "Rezultati më i mirë",
  ////////////////////////
  playOnline: "Luaj online",
  cancel: "Anulo",
  //////// Meta and Description /////////

  metaPageTitle: "Si të luani Sudoku - nga laik në Sudoku Pro",
  metaPageDescription:
    "Lexoni blogun Sudoku Pro për të marrë këshilla dhe sugjerime se si të luani Sudoku, pavarësisht nga nivelu juaj aktual. Merrni shpjegime të thjeshta për strategjitë komplekse të lojës logjike, që të luani më efektivisht dhe të përmirësoni kujtesën tuaj.",

  metaBlogTitle: "Sudoku Online - këshilla dhe truket | Nga fillestar në Sudoku Pro",
  metaBlogDescription:
    "Lexoni blogun tonë Sudoku Pro për të mësuar për të gjitha veçoritë e Sudoku për fillestarë dhe lojtarë të përvojuar. Merrni shpjegime të thjeshta për strategjitë profesionale dhe këshilla për zgjidhjen e Sudoku online.",

  //////Basic rules///////
  gameRules: {
    subtitle: "Sudoku është i thjeshtë!",
    title: "Rregullat themelore të lojës Sudoku",
    titleText:
      "Nëse jeni fillimtar dhe nuk dini si të zgjidhni sudoku, nuk ka asgjë për të turpëruar. Kjo lojë është shumë logjike dhe është shumë e lehtë për të mësuar si të luani sudoku. Ka 3 rregulla kryesore, dhe në varësi të llojit të lojës logjike që zgjidhni, mund të ketë disa nuancat, por rregullat kryesore të lojës sudoku mbeten të pandryshuara.",
    goal: "Objektivi:",
    goalText:
      "Plotësoni rrjetën 9x9 në mënyrë që çdo kolonë, rresht dhe seksion 3x3 të përmbajë numrat nga 1 deri në 9. Tradicionalisht, nuk ka kufizime kohore, por disa sudoku online shtojnë kufizime kohore dhe ngjasojnë të nxitin të mendoni më shpejt.",
    ruleTitle: "Si të luani Sudoku?",
    ruleText:
      "Kur filloni, disa katrorë të rrjetës 9x9 tashmë do të kenë numra. Detyra juaj është të plotësoni hapësirat e zbura, pa lënë asnjë hapësirë të zbrazët.",
    fail: "Ju dështoni nëse:",
    list: "Cdo rresht përmban dy numra të njëjtë <list> Cdo kolonë ka dy numra të njëjtë <list> Çdo seksion 3x3 ka dy numra të njëjtë.",
    remember: "KUJTOHU:",
    rememberText: "Cdo numër nga 1 deri në 9 mund të përdoret vetëm një herë në rresht, kolonë ose seksionin 3x3.",
  },
  playNow: "Luaj Tani",
  /////////// Months ////////
  January: "Janar",
  February: "Shkurt",
  March: "Mars",
  April: "Prill",
  May: "Maj",
  June: "Qershor",
  July: "Korrik",
  August: "Gusht",
  September: "Shtator",
  October: "Tetor",
  November: "Nëntor",
  December: "Dhjetor",
  ///////// Game levels //////
  EASY: "Lehtë",
  MEDIUM: "E mesme",
  HARD: "E vështirë",
  EXPERT: "Ekspert",
  EXTREME: "Ekstreme",
  /////////NoGame RC//////
  sorry: "Na vjen keq",
  gameNotFount: "loja nuk u gjet",
  chooseChallenge: "Zgjidhni një sfidë këtu:",

  // Login Page
  loginMetaTitle: "Hyni në Sudoku Pro",
  loginMetaDescription: "Identifikohu për të ruajtur përparimin e lojës dhe për të konkurruar me lojtarët e tjerë",
  logIn: "Identifikohu",
  welcomeBack: "Mirëse u ktheve",
  continueWithGoogle: "Vazhdo me Google",
  continueWithFacebook: "Vazhdo me Facebook",
  authorizationFailedMessage: "Autorizimi dështoi, provo sërish më vonë",

  // Registration Page
  registrationMetaTitle: "Përfundo regjistrimin - Sudoku Pro",
  registrationMetaDescription:
    "Mbetet vetëm një hap i vogël për të përfunduar regjistrimin dhe për të përfituar sa më shumë nga loja Sudoku",
  createAnAccount: "Krijo nje llogari",
  nickName: "Pseudonimi",
  country: "Vendi",
  signUp: "Regjistrohu",
  createYourNickName: "Krijoni pseudonimin tuaj",
  registrationFailedMessage: "Regjistrimi dështoi, provo sërish më vonë",

  // Logout Dialog
  logOutMessage: "Jeni i sigurt që dëshironi të dilni? Përparimi juaj nuk do të ruhet",
  logOut: "Shkyç",

  // Awards Page
  awardsMetaTitle: "Çmimet tuaja - Sudoku Pro",
  awardsMetaDescription: "Plotësoni sfidat e përditshme në Sudoku Pro dhe mblidhni të gjitha shpërblimet për vitin!",
  awards: "Çmimet",
  yourAwards: "Çmimet tuaja",
  yourAwardsDescription: "Plotësoni sfidat ditore dhe merrni shpërblime në fund të çdo muaji!",
  awardsDontMissASingleDay: "Mos humbisni asnjë ditë - luani sudoku!",

  // Monthly Award Dialog
  awardDialogTitle: "Çmimi mujor i marrë",
  awardDialogDescription: "Ju keni përfunduar të gjitha sfidat ditore për një muaj dhe keni marrë një çmim! urime!",
  awardShare: "Shpërndaje",

  // Authorization banner
  authorizationBannerMessage: "Identifikohu për të ruajtur përparimin dhe çmimet 🏆",

  // You Won Dialog
  levelsLeftMessage: "Nivelet e mbetura për të marrë shpërblimin: %s",

  // Ratings Block
  topCountriesToday: "TOP 20 vendet sot",
  topUsersToday: "TOP 100 Përdoruesit sot",
  numberOfUsers: "Numri i Përdoruesve",
  updatedEvery24hours: "Përditësohet çdo 24 orë",
  experiencePoints: "Pikat e përvojës",
  showFullList: "Shfaq listën e plotë",
  showShortList: "Shfaq një listë të shkurtër",
};

export const de: any = {
  ISOcode: "de",
  countryName: "Germany",
  language: "Deutsch",

  headerAndFooter: {
    moreGames: "Mehr Spiele",
    playSudoku: "Spielen Sie Sudoku",
    playSolitaire: "Spielen Sie Solitaire",
    playMahjong: "Spiele Mahjong",
  },

  home: "Startseite",
  allow: "ermöglichen",
  blog: "Blog",
  dailyChallenges: "Tägliche Herausforderungen",
  dailyChallenge: "TÄGLICHE HERAUSFORDERUNG",
  readBlog: "Blog lesen",
  omisoft: "OmiSoft",
  sudokuPuzzleGame: "Sudoku Pro - Puzzle Spiel",
  sudokuTipsAndTrick: "Sudoku Tipps und Tricks — Vom Noob zum SudokuPro",
  oops: "Ups...",
  errorPageNotFound: "404 Fehler. Seite nicht gefunden",
  sorryGoBackHome:
    "Es tut uns leid, die von Ihnen angeforderte Seite konnte nicht gefunden werden. Bitte gehen Sie zurück zur Homepage.",
  backToHomepage: "ZURÜCK ZUR HOMEPAGE",
  cookieText:
    "Unsere Website verwendet Cookies, um Inhalte zu personalisieren und unseren Datenverkehr zu analysieren, was auch zu einem Profiling führen kann.",
  ok: "OK!",
  privacyPolicy: "Datenschutzbestimmungen",
  howToPlay: "Spielanleitung",
  undo: "Abbrechen",
  notes: "Anmerkungen",
  hints: "Tipps",
  erase: "Löschen Sie",
  newGame: "Neues Spiel starten",
  level: "Eben: ",
  levels: "Ebenen",
  similarArticles: "Ähnlich lautende Artikel",
  playSudokuOnline: "Sudoku online spielen",
  startYourBrainTraining:
    "Beginnen Sie jetzt mit Ihrem Gehirntraining, laden Sie das kostenlose Online-Spiel Sudoku Pro herunter und beschweren Sie sich nicht, dass Sie nicht aufhören können, es zu lösenStarten Sie Ihr Gehirntraining jetzt, laden Sie das kostenlose Online-Spiel Sudoku Pro herunter, und beschweren Sie sich nicht, dass Sie nicht aufhören können, zu lösen.",
  nowDownload:
    "JETZT HERUNTERLADEN JETZT HERUNTERLADEN JETZT HERUNTERLADEN JETZT HERUNTERLADEN JETZT HERUNTERLADEN JETZT HERUNTERLADEN JETZT HERUNTERLADEN",
  playBest: "Spielen Sie am besten",
  online: "Online",
  playSudoku: "Sudoku!",
  seeWeToldYou: "Sehen Sie, wir haben dir gesagt, dass Regeln einfach sind!",
  dontWasteYourTime: "Verschwenden Sie keine Zeit mit Zweifeln, die beste Sudoku-App",
  appIsSudokuPro: "ist Sudoku Pro, und Sie müssen sie ausprobieren!",
  goBack: "Zurückgehen",
  facebook: "Facebook",
  twitter: "Twitter",
  telegram: "Telegram",
  points: "Punkte",
  yes: "Ja",
  no: "NEIN",
  playMahjong: "Spielen Sie Mahjong online",
  siteMap: "Seitenverzeichnis",
  otherGame: "Andere Spiele",
  historySudoku: "Sudoku-Geschichte",
  rulesSudoku: "Regeln für Sudoku",
  tipsSudoku: "Tipps für Sudoku",
  /////Win poup////
  youWin: "Du hast gewonnen!",
  youWinText: "Laden Sie die App herunter, um jederzeit und ohne Einschränkungen zu spielen",
  followUs: "Folgen Sie uns auf sozialen Medien:",
  winLevel: "Ebene",
  startNewGame: "Neues Spiel starten",
  time: "Zeit",
  bestResult: "Bestes Ergebnis",
  ////////////////////////
  playOnline: "online sudoku spielen",
  cancel: "Abbrechen",
  //////// Meta and Description /////////

  metaPageTitle: "Wie man Sudoku spielt — vom Noob zum SudokuPro",
  metaPageDescription:
    "Lesen Sie den SudokuPro Blog, um alle Tipps und Tricks zum Spielen von Sudoku zu erfahren, egal ob Sie ein absoluter Anfänger oder ein fortgeschrittener Spieler sind. Erhalten Sie eine einfache Erklärung der Profi-Strategien, um effektiver zu spielen und Ihre Gehirngesundheit zu fördern.",

  metaBlogTitle: "Sudoku Tipps und Tricks — Vom Noob zum SudokuPro",
  metaBlogDescription:
    "Lesen Sie unseren Blog, um alle Tricks in Sudoku spiel kostenlos für Anfänger und erfahrene Spieler zu lernen. Erhalten Sie eine einfache Erklärung der Profi-Strategien und Tipps zum Lösen von Sudoku",

  //////Basic rules///////

  gameRules: {
    subtitle: "Sudoku leicht gemacht",
    title: "Grundregeln des Sudoku",
    titleText:
      "Wenn Sie ein Neuling in der Sudoku-Welt sind, brauchen Sie sich nicht zu schämen. Dieses Spiel ist sehr logisch und leicht zu verstehen. Es gibt 3 Grundregeln und je nach gewähltem Typ kann es Änderungen geben, aber die Grundregeln ändern sich selten.",
    goal: "Das Ziel:",
    goalText:
      "Ausfüllen eines 9×9-Gitters, so dass jede Spalte, jede Zeile und jeder 3×3-Abschnitt die Ziffern von 1 bis 9 enthält. Traditionell gibt es kein Zeitlimit, aber einige Sudoku-Spiele für das Gehirn fügen Zeitlimits hinzu, die zu schnellerem Denken anregen.",
    ruleTitle: "Wie spielt man Sudoku?",
    ruleText:
      "Wenn Sie anfangen, werden einige Quadrate des 9×9-Gitters bereits Ziffern haben. Ihre Aufgabe ist es, ihr Gehirn zu benutzen, die fehlenden Stellen zu füllen und das Gitter zu vervollständigen.",
    fail: "Sie fallen durch, wenn:",
    list: "Jede Zeile hat zwei gleiche Zahlen <list> Jede Spalte hat zwei gleiche Zahlen <list> Jedes 3×3-Gitter hat zwei gleiche Zahlen",
    remember: "Erinnern Sie sich:",
    rememberText:
      "Jede Ziffer von 1 bis 9 kann nur einmal in einer Zeile, Spalte oder einem 3×3-Raster verwendet werden.",
  },

  playNow: "JETZT SPIELEN",
  /////////// Months ////////
  January: "Januar",
  February: "Februar",
  March: "März",
  April: "April",
  May: "Mai",
  June: "Juni",
  July: "Juli",
  August: "August",
  September: "September",
  October: "October",
  November: "November",
  December: "Dezember",
  EASY: "Einfach",
  MEDIUM: "Mittel",
  HARD: "Schwer",
  EXPERT: "Expert",
  EXTREME: "Extreme",
  /////////NoGame RC//////
  sorry: "entschuldigung",
  gameNotFount: "Spiel nicht gefunden",
  chooseChallenge: "Wählen Sie hier eine Herausforderung:",

  // Login Page
  loginMetaTitle: "Melden Sie sich bei Sudoku Pro an",
  loginMetaDescription:
    "Melden Sie sich an, um Ihren Spielfortschritt zu speichern und mit anderen Spielern zu konkurrieren",
  logIn: "Anmeldung",
  welcomeBack: "Willkommen zurück",
  continueWithGoogle: "Weiter mit Google",
  continueWithFacebook: "Weiter mit Facebook",
  authorizationFailedMessage: "Die Autorisierung ist fehlgeschlagen. Versuchen Sie es später noch einmal",

  // Registration Page
  registrationMetaTitle: "Registrierung abschließen – Sudoku Pro",
  registrationMetaDescription:
    "Es bleibt nur noch ein kleiner Schritt, um die Registrierung abzuschließen und das Sudoku-Spiel optimal zu nutzen",
  createAnAccount: "Ein Konto erstellen",
  nickName: "Spitzname",
  country: "Land",
  signUp: "Melden Sie sich an",
  createYourNickName: "Erstellen Sie Ihren Spitznamen",
  registrationFailedMessage: "Die Registrierung ist fehlgeschlagen. Versuchen Sie es später noch einmal",

  // Logout Dialog
  logOutMessage: "Möchten Sie sich wirklich abmelden? Ihr Fortschritt wird nicht gespeichert",
  logOut: "Ausloggen",

  // Awards Page
  awardsMetaTitle: "Ihre Auszeichnungen – Sudoku Pro",
  awardsMetaDescription:
    "Schließe die täglichen Herausforderungen in Sudoku Pro ab und sammle alle Belohnungen für das Jahr!",
  awards: "Auszeichnungen",
  yourAwards: "Ihre Auszeichnungen",
  yourAwardsDescription: "Schließe tägliche Herausforderungen ab und erhalte am Ende jedes Monats Belohnungen!",
  awardsDontMissASingleDay: "Verpassen Sie keinen einzigen Tag – spielen Sie Sudoku!",

  // Monthly Award Dialog
  awardDialogTitle: "Monatliche Auszeichnung erhalten",
  awardDialogDescription:
    "Du hast einen Monat lang alle täglichen Herausforderungen gemeistert und eine Auszeichnung erhalten! Glückwunsch!",
  awardShare: "Aktie",

  // Authorization banner
  authorizationBannerMessage: "Melden Sie sich an, um Ihre Fortschritte und Auszeichnungen zu speichern 🏆",

  // You Won Dialog
  levelsLeftMessage: "Verbleibende Level, um die Belohnung zu erhalten: %s",

  // Ratings Block
  topCountriesToday: "TOP 20 Länder heute",
  topUsersToday: "TOP 100 Benutzer heute",
  numberOfUsers: "Anzahl der Nutzer",
  updatedEvery24hours: "Alle 24 Stunden aktualisiert",
  experiencePoints: "Erfahrungspunkte",
  showFullList: "Vollständige Liste anzeigen",
  showShortList: "Kurze Liste anzeigen",
};

export const el: any = {
  ISOcode: "el",
  countryName: "Greece",
  language: "Eλληνικά",

  headerAndFooter: {
    moreGames: "Περισσότερα παιχνίδια",
    playSudoku: "Παίξτε Sudoku",
    playSolitaire: "Παίξτε Πασιέντζα",
    playMahjong: "Παίξτε Mahjong",
  },

  dailyChallenges: "Καθημερινές προκλήσεις",
  dailyChallenge: "ΚΑΘΗΜΕΡΙΝΉ ΠΡΌΚΛΗΣΗ",
  level: "Επίπεδο: ",
  levels: "Επίπεδα",
  blog: "Blog",
  privacyPolicy: "Πολιτική απορρήτου",
  howToPlay: "Πώς να παίξεις",
  newGame: " νέουπαιχνιδιού",
  undo: "Αναίρεση",
  notes: "Σημειώσεις",
  hints: "Υποδείξεις",
  erase: "Διαγραφή",
  nowDownload: "ΚΑΤΕΒΑΣΤΕ ΤΩΡΑ ΚΑΤΕΒΑΣΤΕ ΚΑΤΕΒΑΣΤΕ ΤΩΡΑ ΚΑΤΕΒΑΣΤΕ ΤΩΡΑ ΛΗΨΗ ΤΩΡΑ ΚΑΤΕΒΑΣΤΕ ",
  playBest: "Παίξτε καλύτερα",
  online: "Σε σύνδεση",
  playSudoku: "Σουντόκου!",
  seeWeToldYou: "Βλέπετε, σας είπαμε ότι οι κανόνες είναι εύκολοι!",
  dontWasteYourTime: "Τώρα, μη χάνετε το χρόνο σας αμφιβάλλοντας,",
  appIsSudokuPro: "η καλύτερη εφαρμογή sudoku είναι το Sudoku Pro και πρέπει να το δοκιμάσετε!",
  playOnline: "παίξετε online",
  points: "σημεία",
  yes: "Ναί",
  no: "Οχι",
  playMahjong: "Παίξτε Mahjong Online",
  siteMap: "Χάρτης τοποθεσίας",
  otherGame: "Άλλα παιχνίδια",
  historySudoku: "Ιστορία Sudoku",
  rulesSudoku: "Κανόνες για το Sudoku",
  tipsSudoku: "Συμβουλές για το Sudoku",

  sudokuTipsAndTrick: "Συμβουλές και κόλπα Sudoku - Από Noob σε SudokuPro",
  goBack: "Πηγαίνετε πίσω",
  readBlog: "Διαβάστε το Blog",
  playSudokuOnline: "Παίξτε Sudoku online",
  playNow: "ΠΑΙΞΤΕ ΤΩΡΑ",
  cookieText:
    "Ο ιστότοπός μας χρησιμοποιεί cookies για την εξατομίκευση του περιεχομένου και την ανάλυση της επισκεψιμότητάς μας, η οποία μπορεί επίσης να οδηγήσει σε κατάρτιση προφίλ.",
  cancel: "Ακύρωση",
  oops: "Ουπς...",
  errorPageNotFound: "Σφάλμα 404. Η σελίδα δεν βρέθηκε",
  sorryGoBackHome:
    "Λυπούμαστε, η σελίδα που ζητήσατε δεν βρέθηκε. Παρακαλώ επιστρέψτε στην Παιχνίδι προγράμματος περιήγησης αρχική σελίδα.",
  backToHomepage: "ΠΊΣΩ ΣΤΗΝ ΑΡΧΙΚΉ ΣΕΛΊΔΑ",
  similarArticles: "Παρόμοια άρθρα",
  startYourBrainTraining:
    "Ξεκινήστε την εκπαίδευση του εγκεφάλου σας τώρα, κατεβάστε το δωρεάν online παιχνίδι Sudoku Pro και μην παραπονιέστε ότι δεν μπορείτε να σταματήσετε να λύνετε.",
  //////// Meta and Description /////////

  metaPageTitle: "Πώς να παίξετε Sudoku - Από Noob σε SudokuPro",
  metaPageDescription:
    "Διαβάστε το ιστολόγιο SudokuPro για να μάθετε όλες τις συμβουλές και τα κόλπα του παιχνιδιού Sudoku, ανεξάρτητα από το αν είστε εντελώς αρχάριος ή προχωρημένος παίκτης. Πάρτε μια εύκολη εξήγηση των επαγγελματικών στρατηγικών για να παίξετε πιο αποτελεσματικά και να ενισχύσετε την υγεία του εγκεφάλου σας",

  metaBlogTitle: "Συμβουλές και κόλπα Sudoku - Από Noob σε SudokuPro",
  metaBlogDescription:
    "Διαβάστε το blog μας για να μάθετε όλα τα κόλπα στο Sudoku για αρχάριους Διαδικτυακό παιχνίδι και έμπειρους παίκτες. Πάρτε μια εύκολη εξήγηση των επαγγελματικών Λογικό παιχνίδι σε απευθείας σύνδεση στρατηγικών και συμβουλών για την επίλυση του Sudoku",

  /////////////Basic rules/////////////
  gameRules: {
    subtitle: "Το Sudoku έγινε εύκολο",
    title: "Βασικοί κανόνες του Sudoku",
    titleText:
      "Αν είστε αρχάριος στον κόσμο του Sudoku, δεν υπάρχει λόγος να ντρέπεστε. Αυτό το παιχνίδι είναι πολύ λογικό και εύκολο να το καταλάβετε. Υπάρχουν 3 βασικοί κανόνες και ανάλογα με τον τύπο που επιλέγετε μπορεί να υπάρχουν τροποποιήσεις, ωστόσο, οι βασικοί κανόνες που τίθενται σπάνια αλλάζουν.",
    goal: "Ο στόχος:",
    goalText:
      "Να συμπληρώσετε ένα πλέγμα 9×9, έτσι ώστε κάθε στήλη, γραμμή και τμήμα 3×3 να περιέχει τα ψηφία από το 1 έως το 9. Παραδοσιακά δεν υπάρχει χρονικό όριο, αλλά ορισμένα παιχνίδια Sudoku για τον εγκέφαλο προσθέτουν χρονικούς περιορισμούς που ωθούν στο να σκέφτεστε πιο γρήγορα.",
    ruleTitle: "Πώς να παίξετε Sudoku;",
    ruleText:
      "Όταν ξεκινάτε, ορισμένα τετράγωνα του πλέγματος 9×9 θα έχουν ήδη ψηφία. Ο στόχος σας είναι να χρησιμοποιήσετε τη δύναμη του μυαλού σας για να συμπληρώσετε τα σημεία που λείπουν και να συμπληρώσετε το πλέγμα.",
    fail: "Αποτυγχάνετε αν:",
    list: "Οποιαδήποτε γραμμή έχει δύο ίδιους αριθμούς <list> Οποιαδήποτε στήλη έχει δύο ίδιους αριθμούς <list> Οποιοδήποτε πλέγμα 3×3 έχει δύο ίδιους αριθμούς.",
    remember: "Να θυμάστε:",
    rememberText:
      "Κάθε ψηφίο από το 1 έως το 9 μπορεί να χρησιμοποιηθεί μόνο μία φορά σε μια γραμμή, στήλη ή πλέγμα 3×3.",
  },

  /////////// Months ////////
  January: "Ιανουάριος",
  February: "Φεβρουάριος",
  March: "Μάρτιος",
  April: "Απρίλιος",
  May: "Μάιος",
  June: "Ιούνιος",
  July: "Ιούλιος",
  August: "Αύγουστος",
  September: "Σεπτέμβριος",
  October: "Οκτώβριος",
  November: "Νοέμβριος",
  December: "Δεκέμβριος",

  /////Win poup////
  youWin: "Κερδίσατε!",
  youWinText: "Κατεβάστε την εφαρμογή για αναπαραγωγή οποιαδήποτε στιγμή και χωρίς περιορισμούς",
  followUs: "Ακολουθήστε μας στα μέσα κοινωνικής δικτύωσης Παίξτε sudoku online:",
  winLevel: "Επίπεδο",
  startNewGame: "Έναρξη νέου παιχνιδιού",
  time: "Χρόνος",
  bestResult: "Καλύτερο αποτέλεσμα",
  EASY: "Εύκολο",
  MEDIUM: "Μεσαίο",
  HARD: "Σκληρό",
  EXPERT: "Εμπειρογνώμονας",
  EXTREME: "Ακραία",
  /////////NoGame RC//////
  sorry: "Συγγνώμη",
  gameNotFount: "Το παιχνίδι δεν βρέθηκε",
  chooseChallenge: "Επιλέξτε μια πρόκληση εδώ:",
};

export const no: any = {
  ISOcode: "no",
  countryName: "Norge",
  language: "Norsk",

  headerAndFooter: {
    moreGames: "Flere spill",
    playSudoku: "Spill Sudoku",
    playSolitaire: "Spill Solitaire",
    playMahjong: "Spill Mahjong",
  },

  blog: "Blogg",
  dailyChallenges: "Daglige utfordringer",
  dailyChallenge: "DAGLIG TEST",
  readBlog: "Les blogg",
  omisoft: "OmiSoft",
  sudokuPuzzleGame: "Sudoku Pro - Puslespill",

  // 404 Page
  oops: "Oops...",
  errorPageNotFound: "404 feil. Side ikke funnet",
  sorryGoBackHome: "Vi beklager, siden du ba om ble ikke funnet. Vennligst gå tilbake til hjemmesiden.",
  backToHomepage: "TILBAKE TIL HJEMMESIDEN",
  goBack: "Gå tilbake",
  // Cookie Popup
  cookieText:
    "Nettstedet vårt bruker informasjonskapsler for å tilpasse innhold og analysere trafikken vår, noe som også kan resultere i profilering.",
  ok: "OK",

  privacyPolicy: "Personvernerklæring",
  howToPlay: "Hvordan spille",
  undo: "Avbryt",
  notes: "Notater",
  hints: "Hint",
  erase: "Viske ut",
  newGame: "Nytt spill",
  level: "Nivå: ",
  levels: "Nivåer",
  similarArticles: "Lignende artikler",
  playSudokuOnline: "Spill Sudoku online",
  startYourBrainTraining:
    "Start hjernetreningen nå, last ned gratis onlinespillet Sudoku, og ikke klag på at du ikke kan slutte å spille.",
  nowDownload: "LAST NÅ LAST NÅ LAST NÅ LAST NÅ LAST NÅ LAST NÅ LAST NÅ LAST NÅ LAST NÅ LAST NÅ LAST NÅ LAST NÅ",
  playBest: "Spill best",
  online: "på nett",
  playSudoku: "Sudoku!",
  seeWeToldYou: "Se, vi fortalte deg at regler er enkle!",
  dontWasteYourTime: "Nå, ikke kast bort tiden din på å tvile, den beste ",
  appIsSudokuPro: "sudoku-appen er Sudoku Pro, og du må prøve den!",
  facebook: "Facebook",
  twitter: "Twitter",
  telegram: "Telegram",
  points: "poeng",
  yes: "Ja",
  no: "Nei",
  playMahjong: "Spill Mahjong Online",
  siteMap: "Områdekart",
  otherGame: "Andre spill",
  historySudoku: "Sudoku historie",
  rulesSudoku: "Regler for Sudoku",
  tipsSudoku: "Tips for Sudoku",
  /////Win poup////
  youWin: "Du vinner!",
  youWinText: "Last ned appen for å spille når som helst og med en rekke fordeler",
  followUs: "Følg oss på sosiale medier:",
  winLevel: "Nivå",
  startNewGame: "Start nytt spill",
  time: "Tid",
  bestResult: "Beste resultat",
  ////////////////////////
  playOnline: "spill online",
  cancel: "Avbryt",
  //////// Meta and Description /////////
  // Blog Page Sreen
  metaPageTitle: "Slik spiller du Sudoku - Fra nybegynner til profesjonell",
  metaPageDescription:
    "Les SudokuPro-bloggen for å lære alle tips og triks om å spille Sudoku, uansett om du er en absolutt nybegynner eller en erfaren spiller. Få en enkel forklaring på proffstrategier for å spille mer effektivt og styrke hjernens helse.",

  // Blog Screen
  metaBlogTitle: "Sudoku tips og triks – Fra nybegynner til profesjonell",
  metaBlogDescription:
    "Les bloggen vår for å lære alle triksene i Sudoku, både for nybegynnere og erfarne spillere. Få en enkel forklaring på proffe strategier og tips for å løse Sudoku.",
  sudokuTipsAndTrick: "Sudoku på nett - tips og triks, eller hvordan bli en profesjonell",

  //////Basic rules///////

  gameRules: {
    subtitle: "Sudoku er enkelt!",
    title: "Grunnleggende regler for Sudoku-spill",
    titleText:
      "Hvis du er nybegynner i Sudoku-verdenen, er det ingenting å skamme seg over. Dette spillet er veldig logisk og lett å forstå. Det er 3 grunnleggende regler, og avhengig av hvilken type du velger, kan det være variasjoner, men de grunnleggende reglene endres sjelden.",
    goal: "Mål:",
    goalText:
      "Å fylle inn en 9x9-rutenett, slik at hver kolonne, rad og 3x3-seksjon inneholder tallene fra 1 til 9. Tradisjonelt sett er det ingen tidsbegrensning, men noen Sudoku-spill for hjernen legger til tidsbegrensninger som utfordrer til å tenke raskere.",
    ruleTitle: "Hvordan spille Sudoku?",
    ruleText:
      "Når du starter, vil noen ruter i 9x9-rutenettet allerede ha tall. Oppgaven din er å bruke hjernens kapasitet til å fylle inn de manglende plassene og fullføre rutenettet.",
    fail: "Du mislykkes hvis:",
    list: "Enhver rad har to like tall <list> Enhver kolonne har to like tall <list> Enhver 3x3-rutenett har to like tall",
    remember: "Huske:",
    rememberText: "Hvert tall fra 1 til 9 kan bare brukes én gang i en rad, kolonne eller 3x3-rutenett.",
  },
  playNow: "LEKE NÅ",
  /////////// Months ////////
  January: "Januar",
  February: "Februar",
  March: "Mars",
  April: "April",
  May: "Mai",
  June: "Juni",
  July: "Juli",
  August: "August",
  September: "September",
  October: "Oktober",
  November: "November",
  December: "Desember",
  ///////// Game levels //////
  EASY: "Lett",
  MEDIUM: "Medium",
  HARD: "Hard",
  EXPERT: "Ekspert",
  EXTREME: "Ekstrem",
  /////////NoGame RC//////
  sorry: "beklager",
  gameNotFount: "spillet ikke funnet",
  chooseChallenge: "Velg en test:",

  // Login Page
  loginMetaTitle: "Logg på Sudoku Pro",
  loginMetaDescription: "Logg på for å lagre spillets fremgang og konkurrere med andre spillere",
  logIn: "Logg Inn",
  welcomeBack: "Velkommen tilbake",
  continueWithGoogle: "Fortsett med Google",
  continueWithFacebook: "Fortsett med Facebook",
  authorizationFailedMessage: "Autorisasjonen mislyktes. Prøv igjen senere",

  // Registration Page
  registrationMetaTitle: "Fullfør registrering - Sudoku Pro",
  registrationMetaDescription:
    "Det er bare ett lite skritt igjen for å fullføre registreringen og få mest mulig ut av Sudoku-spillet",
  createAnAccount: "Opprett en konto",
  nickName: "Kallenavn",
  country: "Land",
  signUp: "Melde deg på",
  createYourNickName: "Lag ditt kallenavn",
  registrationFailedMessage: "Registreringen mislyktes. Prøv igjen senere",

  // Logout Dialog
  logOutMessage: "Er du sikker på at du vil logge ut? Fremgangen din vil ikke bli lagret",
  logOut: "Logg ut",

  // Awards Page
  awardsMetaTitle: "Dine priser - Sudoku Pro",
  awardsMetaDescription: "Fullfør de daglige utfordringene i Sudoku Pro og samle alle årets belønninger!",
  awards: "Priser",
  yourAwards: "Dine utmerkelser",
  yourAwardsDescription: "Fullfør daglige utfordringer og få belønninger på slutten av hver måned!",
  awardsDontMissASingleDay: "Ikke gå glipp av en eneste dag - spill sudoku!",

  // Monthly Award Dialog
  awardDialogTitle: "Månedlig pris mottatt",
  awardDialogDescription: "Du har fullført alle daglige utfordringer i en måned og mottatt en pris! Gratulerer!",
  awardShare: "Dele",

  // Authorization banner
  authorizationBannerMessage: "Logg inn for å lagre fremgangen din og utmerkelser 🏆",

  // You Won Dialog
  levelsLeftMessage: "Nivåer igjen for å få belønningen: %s",

  // Ratings Block
  topCountriesToday: "TOP 20 landene i dag",
  topUsersToday: "TOPP 100 brukere i dag",
  numberOfUsers: "Antall brukere",
  updatedEvery24hours: "Oppdateres hver 24 timer",
  experiencePoints: "Erfaringspoeng",
  showFullList: "Vis hele listen",
  showShortList: "Vis en kort liste",
};

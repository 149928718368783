export const ja: any = {
  ISOcode: "ja",
  countryName: "日本",
  language: "日本語",

  headerAndFooter: {
    moreGames: "その他のゲーム",
    playSudoku: "数独をプレイする",
    playSolitaire: "ソリティアをプレイする",
    playMahjong: "麻雀をプレイする",
  },

  blog: "ブログ",
  dailyChallenges: "毎日の挑戦",
  dailyChallenge: "毎日の挑戦",
  readBlog: "ブログを読む",
  omisoft: "OmiSoft",
  sudokuPuzzleGame: "Sudoku Pro — パズル ゲーム",

  // 404 Page
  oops: "おお",
  errorPageNotFound: "404エラー。 ページが見つかりません",
  sorryGoBackHome: "申し訳ありませんが、お探しのページは見つかりませんでした。トップページに戻ってください。",
  backToHomepage: "ホームページに戻ります",
  goBack: "戻る",
  // Cookie Popup
  cookieText:
    "サイトを閲覧し続けることで、サイトのプライバシーポリシーに精通し、クッキーファイルの使用に同意することを確認します。",
  ok: "オーケー",

  privacyPolicy: "プライバシーポリシー",
  howToPlay: "遊び方",
  undo: "元に戻す",
  notes: "ノート",
  hints: "ヒント",
  erase: "消去",
  newGame: "新しいゲーム",
  level: "レベル： ",
  levels: "レベル",
  similarArticles: "類似記事",
  playSudokuOnline: "数独をオンラインでプレイする",
  startYourBrainTraining:
    "今すぐ脳トレーニングを始めましょう。無料のオンラインゲーム、Sudoku Proをダウンロードして、解くことをやめられないと文句を言わないでください。",
  nowDownload:
    "ダウンロード | ダウンロード | ダウンロード | ダウンロード | ダウンロード | ダウンロード | ダウンロード | ダウンロード | ダウンロード",
  playBest: "最高のプレーをする",
  online: "オンライン",
  playSudoku: "数独！",
  seeWeToldYou: "ご覧の通り、ルールは簡単ですね！",
  dontWasteYourTime: "今は疑う時間を無駄にせず、最高の数独アプリである",
  appIsSudokuPro: "Sudoku Proを試してみてください！",
  facebook: "フェイスブック",
  twitter: "ツイッター",
  telegram: "電報",
  points: "ポイント",
  yes: "はい",
  no: "いいえ",
  playMahjong: "オンラインで麻雀をプレイする",
  siteMap: "サイトマップ",
  otherGame: "その他のゲーム",
  historySudoku: "数独の歴史",
  rulesSudoku: "数独のルール",
  tipsSudoku: "数独のヒント",
  /////Win poup////
  youWin: "勝利！",
  youWinText: "アプリをダウンロードすると、いつでも制限なくプレイできます",
  followUs: "ソーシャルネットワークで私たちをフォローしてください:",
  winLevel: "レベル",
  startNewGame: "新しいゲームを始める",
  time: "時間",
  bestResult: "最高の結果",
  ////////////////////////
  playOnline: "オンラインでプレイする",
  cancel: "キャンセル",
  //////// Meta and Description /////////
  // Blog Page Sreen
  metaPageTitle: "初心者からSudoku Proまでの数独のプレイ方法",
  metaPageDescription:
    "Sudoku Proのブログを読んで、現在のレベルに関係なく、数独をプロとしてプレイするためのヒントやヒントを手に入れましょう。論理ゲームの複雑な戦略を簡単に説明し、効率的にプレイして記憶力を向上させるためのガイドを得ることができます。",

  // Blog Screen
  metaBlogTitle: "オンライン数独 - ヒントとヒント | 初心者からSudoku Proまで",
  metaBlogDescription:
    "当社のSudoku Proブログをお読みいただき、初心者や経験豊富なプレイヤー向けの数独のすべての特徴についてご確認ください。プロの戦略やオンライン数独の解決に関する簡単な説明やアドバイスを入手できます。",
  sudokuTipsAndTrick: "オンライン数独 - ヒントとヒント、プロになる方法",

  //////Basic rules///////
  gameRules: {
    subtitle: "数独は簡単です！",
    title: "数独の基本ルール",
    titleText:
      "数独の世界に初めて入る人であれば、恥ずかしく思うことはありません。このゲームは非常に論理的で、理解するのは簡単です。3つの基本的なルールがあり、選んだタイプによっては変更があるかもしれませんが、基本的なルールはほとんど変わりません。",
    goal: "ゴール：",
    goalText:
      "9×9のグリッドに、各列、行、および3×3のセクションに1から9までの数字が入るようにします。伝統的には時間制限はありませんが、一部の数独ゲームでは時間制限が設けられており、より速く考えるように促されます。",
    ruleTitle: "数独の遊び方は？",
    ruleText:
      "開始時点では、9×9のグリッドの一部のマスにはすでに数字が入っています。あなたの課題は、脳力を使って欠けている部分を埋め、グリッドを完成させることです。",
    fail: "次の場合は負けます。",
    list: "もし以下の条件が満たされている場合、失敗となります： <list> 任意の行に同じ数字が2つある場合 <list> 任意の列に同じ数字が2つある場合 <list> 任意の3×3のグリッドに同じ数字が2つある場合",
    remember: "覚えて：",
    rememberText:
      "数字1から9までの各数字は、それぞれの行、列、または3×3のグリッドにおいて一度だけ使用することができます。",
  },

  playNow: "今すぐプレイ",
  /////////// Months ////////
  January: "一月",
  February: "二月",
  March: "三月",
  April: "四月",
  May: "五月",
  June: "六月",
  July: "七月",
  August: "八月",
  September: "九月",
  October: "十月",
  November: "十一月",
  December: "十二月",
  ///////// Game levels //////
  EASY: "簡単",
  MEDIUM: "ふつう",
  HARD: "むずかしい",
  EXPERT: "エキスパート",
  EXTREME: "過激",
  /////////NoGame RC//////
  sorry: "ごめん",
  gameNotFount: "ゲームが見つかりません",
  chooseChallenge: "テストを選択してください:",

  // Login Page
  loginMetaTitle: "数独プロにログインする",
  loginMetaDescription: "ログインしてゲームの進行状況を保存し、他のプレイヤーと競争します",
  logIn: "ログイン",
  welcomeBack: "おかえり",
  continueWithGoogle: "Google を続ける",
  continueWithFacebook: "Facebookを続ける",
  authorizationFailedMessage: "認証に失敗しました。後でもう一度お試しください",

  // Registration Page
  registrationMetaTitle: "登録を完了する - Sudoku Pro",
  registrationMetaDescription:
    "登録を完了して数独ゲームを最大限に活用するには、あとは小さなステップが 1 つだけ残っています",
  createAnAccount: "アカウントを作成する",
  nickName: "ニックネーム",
  country: "国",
  signUp: "サインアップ",
  createYourNickName: "ニックネームを作成します",
  registrationFailedMessage: "登録に失敗しました。後でもう一度お試しください",

  // Logout Dialog
  logOutMessage: "ログアウトしてもよろしいですか? 進行状況は保存されません",
  logOut: "ログアウト",

  // Awards Page
  awardsMetaTitle: "あなたの賞 - Sudoku Pro",
  awardsMetaDescription: "Sudoku Pro の毎日のチャレンジを完了して、年間の報酬をすべて集めましょう!",
  awards: "受賞歴",
  yourAwards: "あなたの受賞歴",
  yourAwardsDescription: "毎日のチャレンジを完了して、毎月の終わりに報酬を獲得してください!",
  awardsDontMissASingleDay: "一日もお見逃しなく - 数独をプレイしてください!",

  // Monthly Award Dialog
  awardDialogTitle: "月間賞を受賞しました",
  awardDialogDescription: "1 か月間毎日のチャレンジをすべて完了し、賞を獲得しました。 おめでとう！",
  awardShare: "共有",

  // Authorization banner
  authorizationBannerMessage: "ログインして進行状況と特典を保存 🏆",

  // You Won Dialog
  levelsLeftMessage: "報酬を獲得するまでの残りレベル: %s",

  // Ratings Block
  topCountriesToday: "今日の上位 20 か国",
  topUsersToday: "今日のトップ 100 ユーザー",
  numberOfUsers: "ユーザー数",
  updatedEvery24hours: "24時間ごとに更新されます",
  experiencePoints: "経験値",
  showFullList: "完全なリストを表示",
  showShortList: "短いリストを表示",
};

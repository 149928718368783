export const th: any = {
  ISOcode: "th",
  countryName: "ประเทศไทย",
  language: "แบบไทย",

  headerAndFooter: {
    moreGames: "เกมเพิ่มเติม",
    playSudoku: "เล่นซูโดกุ",
    playSolitaire: "เล่น โซลิแทร์",
    playMahjong: "เล่นมาจง",
  },

  blog: "บล็อก",
  dailyChallenges: "ความท้าทายรายวัน",
  dailyChallenge: "ความท้าทายในชีวิตประจำวัน",
  readBlog: "อ่านบล็อก",
  omisoft: "OmiSoft",
  sudokuPuzzleGame: "Sudoku Pro — เกมปริศนา",
  sudokuTipsAndTrick: "ซูโดกุออนไลน์ - เคล็ดลับและคำแนะนำ หรือวิธีการเป็นมืออาชีพ",
  oops: "อุ๊ย...",
  errorPageNotFound: "ข้อผิดพลาด 404 ไม่พบหน้านี้",
  sorryGoBackHome: "ขออภัย ไม่พบหน้าที่คุณร้องขอ กรุณากลับไปที่หน้าแรก.",
  backToHomepage: "กลับสู่หน้าแรก",
  cookieText: "เว็บไซต์ของเราใช้คุกกี้เพื่อปรับแต่งเนื้อหาและวิเคราะห์การเข้าชมของเราซึ่งอาจส่งผลให้เกิดโปรไฟล์",
  ok: "ตกลง",
  privacyPolicy: "นโยบายความเป็นส่วนตัว",
  howToPlay: "วิธีการเล่น",
  undo: "เลิกทำ",
  notes: "หมายเหตุ",
  hints: "คำแนะนำ",
  erase: "ลบ",
  newGame: "เกมส์ใหม่",
  level: "ระดับ: ",
  levels: "ระดับ",
  similarArticles: "บทความที่คล้ายกัน",
  playSudokuOnline: "เล่นซูโดกุออนไลน์",
  startYourBrainTraining:
    "เริ่มฝึกสมองตอนนี้ ดาวน์โหลดเกมออนไลน์ฟรี Sudoku Pro และอย่าบ่นว่าคุณไม่สามารถหยุดแก้ปัญหาได้",
  nowDownload: "ดาวน์โหลดเดี๋ยวนี้ ดาวน์โหลดเดี๋ยวนี้ ดาวน์โหลดเดี๋ยวนี้ ดาวน์โหลดเดี๋ยวนี้ ดาวน์โหลดเดี๋ยวนี้",
  playBest: "เล่นให้ดีที่สุด",
  online: "ออนไลน์",
  playSudoku: "ซูโดกุ!",
  seeWeToldYou: "เห็นไหมเราบอกคุณแล้วว่ากฎนั้นง่าย!",
  dontWasteYourTime: "ตอนนี้ อย่าเสียเวลาไปสงสัยเลย แอป Sudoku",
  appIsSudokuPro: "ที่ดีที่สุดคือ Sudoku Pro และคุณต้องลองใช้ดู!",
  goBack: "กลับไป",
  facebook: "Facebook",
  twitter: "Twitter",
  telegram: "Telegram",
  points: "คะแนน",
  yes: "ใช่",
  no: "เลขที่",
  playMahjong: "เล่นเกมไพ่นกกระจอกออนไลน์",
  siteMap: "แผนที่เว็บไซต์",
  otherGame: "เกมส์อื่นๆ",
  historySudoku: "ประวัติซูโดกุ",
  rulesSudoku: "กฎสำหรับซูโดกุ",
  tipsSudoku: "เคล็ดลับสำหรับซูโดกุ",
  /////Win poup////
  youWin: "คุณได้รับรางวัล!",
  youWinText: "ดาวน์โหลดแอปเพื่อเล่นได้ทุกเวลาพร้อมสิทธิประโยชน์มากมาย",
  followUs: "ติดตามเราบนโซเชียลมีเดีย:",
  winLevel: "ระดับ",
  startNewGame: "เริ่มเกมใหม่",
  time: "เวลา",
  bestResult: "ผลลัพธ์ที่ดีที่สุด",
  ////////////////////////
  playOnline: "เล่นออนไลน์",
  cancel: "ยกเลิก",
  //////// Meta and Description /////////
  metaPageTitle: "วิธีเล่นซูโดกุ — จาก Noob ไปจนถึง SudokuPro",
  metaPageDescription:
    "อ่านบล็อก SudokuPro เพื่อเรียนรู้เคล็ดลับและคำแนะนำในการเล่น Sudoku ไม่ว่าคุณจะเป็นผู้เล่นมือใหม่หรือผู้เล่นขั้นสูงก็ตาม รับคำอธิบายง่ายๆ เกี่ยวกับกลยุทธ์ระดับโปรเพื่อการเล่นอย่างมีประสิทธิภาพและเสริมสุขภาพสมองของคุณ",

  metaBlogTitle: "เคล็ดลับและเทคนิคซูโดกุ — จาก Noob ไปจนถึง SudokuPro",
  metaBlogDescription:
    "อ่านบล็อกของเราเพื่อเรียนรู้เคล็ดลับทั้งหมดใน Sudoku สำหรับผู้เริ่มต้นและผู้เล่นที่เชี่ยวชาญ รับคำอธิบายง่ายๆ เกี่ยวกับกลยุทธ์ระดับมืออาชีพและเคล็ดลับในการแก้ปัญหา Sudoku",

  //////Basic rules///////

  gameRules: {
    subtitle: "ซูโดกุทำได้ง่าย",
    title: "กฎพื้นฐานของซูโดกุ",
    titleText:
      "หากคุณเป็นมือใหม่ในโลก Sudoku ไม่มีอะไรต้องละอายใจ เกมนี้มีเหตุผลและง่ายต่อการรับ มีกฎพื้นฐาน 3 ข้อ และอาจมีการเปลี่ยนแปลงขึ้นอยู่กับประเภทที่คุณเลือก อย่างไรก็ตาม กฎพื้นฐานที่ตั้งไว้แทบจะไม่เปลี่ยนแปลง",
    goal: "เป้าหมาย:",
    goalText:
      "กรอกตารางขนาด 9×9 เพื่อให้แต่ละคอลัมน์ แถว และส่วน 3×3 มีตัวเลขระหว่าง 1 ถึง 9 ตามธรรมเนียมแล้วไม่มีการจำกัดเวลา แต่เกม Sudoku สำหรับสมองบางเกมจะเพิ่มการจำกัดเวลาที่ผลักดันให้คิดเร็วขึ้น.",
    ruleTitle: "วิธีเล่นซูโดกุ?",
    ruleText:
      "เมื่อคุณเริ่มต้น ช่องสี่เหลี่ยมบางช่องของตาราง 9×9 จะมีตัวเลขอยู่แล้ว งานของคุณคือการใช้พลังสมองเพื่อเติมเต็มจุดที่ขาดหายไปและทำให้ตารางสมบูรณ์",
    fail: "คุณล้มเหลวหาก:",
    list: "แถวใดๆ ที่มีตัวเลขเหมือนกันสองตัว <list> คอลัมน์ใดๆ ที่มีตัวเลขเหมือนกันสองตัว <list> ตาราง 3×3 ใดๆ ก็ตามจะมีตัวเลขที่เหมือนกันสองตัว",
    remember: "จดจำ:",
    rememberText: "แต่ละหลักตั้งแต่ 1 ถึง 9 สามารถใช้ได้เพียงครั้งเดียวในแถว คอลัมน์ หรือตาราง 3×3",
  },

  playNow: "เล่นเลย",
  /////////// Months ////////
  January: "มกราคม",
  February: "กุมภาพันธ์",
  March: "มีนาคม",
  April: "เมษายน",
  May: "พฤษภาคม",
  June: "มิถุนายน",
  July: "กรกฎาคม",
  August: "สิงหาคม",
  September: "กันยายน",
  October: "ตุลาคม",
  November: "พฤศจิกายน",
  December: "ธันวาคม",
  ///////// Game levels //////
  EASY: "ง่าย",
  MEDIUM: "ปานกลาง",
  HARD: "แข็ง",
  EXPERT: "ผู้เชี่ยวชาญ",
  EXTREME: "สุดขีด",
  /////////NoGame RC//////
  sorry: "ขอโทษ",
  gameNotFount: "ไม่พบเกม",
  chooseChallenge: "เลือกความท้าทายที่นี่:",

  // Login Page
  loginMetaTitle: "เข้าสู่ระบบ Sudoku Pro",
  loginMetaDescription: "เข้าสู่ระบบเพื่อบันทึกความคืบหน้าของเกมและแข่งขันกับผู้เล่นคนอื่น",
  logIn: "เข้าสู่ระบบ",
  welcomeBack: "ยินดีต้อนรับกลับ",
  continueWithGoogle: "ดำเนินการต่อด้วย Google",
  continueWithFacebook: "ต่อด้วยเฟสบุ๊ค",
  authInfoModalTitleFailed: "การอนุญาตล้มเหลว",
  authorizationFailedMessage: "การอนุญาตล้มเหลว โปรดลองอีกครั้งในภายหลัง",

  // Registration Page
  registrationMetaTitle: "เสร็จสิ้นการลงทะเบียน - Sudoku Pro",
  registrationMetaDescription:
    "เหลือเพียงขั้นตอนเล็กๆ เพียงหนึ่งขั้นตอนในการลงทะเบียนให้เสร็จสิ้นและใช้ประโยชน์สูงสุดจากเกม Sudoku",
  createAnAccount: "สร้างบัญชี",
  nickName: "ชื่อเล่น",
  country: "ประเทศ",
  signUp: "ลงชื่อ",
  createYourNickName: "สร้างชื่อเล่นของคุณ",
  authInfoModalRegistrationTitleErrors: "การลงทะเบียนไม่สำเร็จ",
  registrationFailedMessage: "การลงทะเบียนล้มเหลว โปรดลองอีกครั้งในภายหลัง",

  // Logout Dialog
  logOutMessage: "คุณแน่ใจว่าคุณต้องการที่จะออกจากระบบ? ความคืบหน้าของคุณจะไม่ถูกบันทึก",
  logOut: "ออกจากระบบ",

  // Awards Page
  awardsMetaTitle: "รางวัลของคุณ - Sudoku Pro",
  awardsMetaDescription: "พิชิตความท้าทายรายวันใน Sudoku Pro และสะสมรางวัลทั้งหมดสำหรับปี!",
  awards: "รางวัล",
  yourAwards: "รางวัลของคุณ",
  yourAwardsDescription: "ทำภารกิจท้าทายรายวันให้สำเร็จและรับรางวัลทุกสิ้นเดือน!",
  awardsDontMissASingleDay: "อย่าพลาดสักวัน - เล่นซูโดกุ!",
  getAwards: "รับรางวัล!",

  // Monthly Award Dialog
  awardDialogTitle: "ได้รับรางวัลประจำเดือน",
  awardDialogDescription: "คุณผ่านการท้าทายรายวันทั้งหมดเป็นเวลาหนึ่งเดือนและได้รับรางวัล! ยินดีด้วย!",
  awardShare: "แบ่งปัน",

  // Authorization banner
  authorizationBannerMessage: "เข้าสู่ระบบเพื่อบันทึกความก้าวหน้าและรางวัลของคุณ 🏆",

  // You Won Dialog
  levelsLeftMessage: "🏆ระดับที่เหลือเพื่อรับรางวัล: %s",
  finalPoints: "Final points",

  // Ratings Block
  topCountriesToday: "20 อันดับแรกของประเทศวันนี้",
  topUsersToday: "ผู้ใช้ 100 อันดับแรกวันนี้",
  numberOfUsers: "จำนวนผู้ใช้",
  updatedEvery24hours: "อัพเดททุกๆ 24 ชม",
  experiencePoints: "คะแนนประสบการณ์",
  showFullList: "แสดงรายการทั้งหมด",
  showShortList: "แสดงรายการสั้น ๆ",

  // ControlHintsModalWarning
  controlHintsModalWarningMessage: "คุณสมบัตินี้ไม่สามารถใช้ได้ในการท้าทายรายวัน",

  // ConsecutiveDaysWarningModal
  consecutiveDaysModalText: "จำนวนวันเล่นเกมติดต่อกัน เล่นทุกวันเพื่อปรับปรุงความสำเร็จนี้!",
  consecutiveDaysModalTextLogIn: "ลงชื่อเข้าใช้เพื่อบันทึกความสำเร็จของคุณ",
};

export const id: any = {
  ISOcode: "id",
  countryName: "Indonesia",
  language: "Indonesian",

  headerAndFooter: {
    moreGames: "Lebih Banyak Game",
    playSudoku: "Mainkan Sudoku",
    playSolitaire: "Mainkan Solitaire",
    playMahjong: "Mainkan Mahjong",
  },

  dailyChallenges: "Tantangan Harian",
  dailyChallenge: "TANTANGAN HARIAN",
  level: "Tingkat: ",
  levels: "Tingkat",
  blog: "Blog",
  privacyPolicy: "Kebijakan Privasi",
  howToPlay: "Cara bermain",
  newGame: "permainan baru",
  undo: "Urungkan",
  notes: "Catatan",
  hints: "Petunjuk",
  erase: "Menghapus",
  nowDownload:
    "SEKARANG UNDUH SEKARANG UNDUH SEKARANG UNDUH SEKARANG UNDUH SEKARANG UNDUH SEKARANG UNDUH SEKARANG UNDUH SEKARANG ",
  playBest: "Mainkan yang terbaik",
  online: "Online",
  playSudoku: "Sudoku!",
  seeWeToldYou: "Lihat, kami sudah bilang bahwa aturannya mudah!",
  dontWasteYourTime: "Sekarang, jangan buang waktu Anda untuk meragukannya, ",
  appIsSudokuPro: "aplikasi sudoku terbaik adalah Sudoku Pro, dan Anda harus mencobanya!",
  playOnline: "bermain online",
  points: "poin",
  yes: "Ya",
  no: "TIDAK",
  playMahjong: "Mainkan Mahjong Daring",
  siteMap: "Peta situs",
  otherGame: "Permainan lainnya",
  historySudoku: "Sejarah Sudoku",
  rulesSudoku: "Aturan Sudoku",
  tipsSudoku: "Tip untuk Sudoku",

  sudokuTipsAndTrick: "Tips dan Trik Sudoku - Dari Noob Hingga SudokuPro",
  goBack: "Kembali",
  readBlog: "Baca Blog",
  playSudokuOnline: "Bermain Sudoku Online",
  playNow: "MAINKAN SEKARANG",
  cookieText:
    "Situs web kami menggunakan cookie untuk mempersonalisasi konten dan menganalisis lalu lintas kami yang juga dapat menghasilkan profil.",
  cancel: "Batalkan",
  oops: "Ups...",
  errorPageNotFound: "Kesalahan 404. Halaman tidak ditemukan",
  sorryGoBackHome: "Maaf, halaman yang Anda cari tidak ditemukan. Silakan kembali ke beranda.",
  backToHomepage: "KEMBALI KE BERANDA",
  similarArticles: "Artikel Serupa",
  startYourBrainTraining:
    "Mulai latih otak Anda sekarang, unduh game online gratis Sudoku Pro, dan jangan mengeluh bahwa Anda tidak bisa berhenti memecahkannya.",
  //////// Meta and Description /////////
  metaPageTitle: "Cara Bermain Sudoku - Dari Noob Hingga SudokuPro",
  metaPageDescription:
    "Baca blog SudokuPro untuk mempelajari semua tips dan trik bermain Sudoku, baik Anda seorang pemula maupun pemain tingkat lanjut. Dapatkan penjelasan mudah tentang strategi pro untuk bermain lebih efektif dan meningkatkan kesehatan otak Anda",

  metaBlogTitle: "Tips dan Trik Sudoku - Dari Noob Hingga SudokuPro",
  metaBlogDescription:
    "Baca blog kami untuk mempelajari semua trik Sudoku untuk pemula dan pemain ahli. Dapatkan penjelasan mudah tentang strategi pro dan tips untuk memecahkan Sudoku",

  /////////////Basic rules/////////////
  gameRules: {
    subtitle: "Sudoku menjadi mudah",
    title: "Aturan dasar Sudoku",
    titleText:
      "Jika Anda seorang pemula di dunia Sudoku, tidak perlu malu. Permainan ini sangat logis dan mudah dipahami. Ada 3 aturan dasar dan tergantung pada jenis yang Anda pilih, mungkin ada perubahan, namun aturan dasar yang ditetapkan jarang berubah.",
    goal: "Tujuan:",
    goalText:
      "Mengisi kotak 9×9, sehingga setiap kolom, baris, dan bagian 3×3 berisi angka antara 1 sampai 9. Secara tradisional tidak ada batasan waktu, tetapi beberapa permainan Sudoku untuk otak menambahkan batasan waktu yang mendorong untuk berpikir lebih cepat.",
    ruleTitle: "Bagaimana cara bermain Sudoku?",
    ruleText:
      "Saat Anda memulai, beberapa kotak dari kotak 9×9 sudah memiliki angka. Tugas Anda adalah menggunakan kemampuan otak Anda untuk mengisi titik-titik yang hilang dan menyelesaikan kotak.",
    fail: "Anda gagal jika:",
    list: "Setiap baris memiliki dua angka yang sama <list> Setiap kolom memiliki dua angka yang sama <list> Setiap petak 3×3 memiliki dua angka yang sama",
    remember: "Ingat:",
    rememberText: "Setiap angka dari 1 hingga 9 hanya dapat digunakan sekali dalam baris, kolom, atau kotak 3×3",
  },

  /////////// Months ////////
  January: "Januari",
  February: "Februari",
  March: "Maret",
  April: "April",
  May: "Mei",
  June: "Juni",
  July: "Juli",
  August: "Agustus",
  September: "September",
  October: "Oktober",
  November: "November",
  December: "Desember",

  /////Win poup////
  youWin: "Kamu menang!!",
  youWinText: "Unduh aplikasi untuk bermain kapan saja dan tanpa batasan apa pun",
  followUs: "Ikuti Kami di Media Sosial:",
  winLevel: "Tingkat",
  startNewGame: "Memulai Permainan Baru",
  time: "Waktu",
  bestResult: "Hasil Terbaik",
  EASY: "Mudah",
  MEDIUM: "Sedang",
  HARD: "Sulit",
  EXPERT: "Ahli",
  EXTREME: "Ekstrim",
  /////////NoGame RC//////
  sorry: "maaf",
  gameNotFount: "permainan tidak ditemukan",
  chooseChallenge: "Pilih tantangan di sini:",

  // Login Page
  loginMetaTitle: "Masuk ke Sudoku Pro",
  loginMetaDescription: "Masuk untuk menyimpan kemajuan permainan Anda dan bersaing dengan pemain lain",
  logIn: "Gabung",
  welcomeBack: "Selamat Datang kembali",
  continueWithGoogle: "Lanjutkan dengan Google",
  continueWithFacebook: "Lanjutkan dengan Facebook",
  authorizationFailedMessage: "Otorisasi gagal, coba lagi nanti",

  // Registration Page
  registrationMetaTitle: "Selesaikan Pendaftaran - Sudoku Pro",
  registrationMetaDescription:
    "Tinggal satu langkah kecil lagi untuk menyelesaikan pendaftaran dan mendapatkan hasil maksimal dari permainan Sudoku",
  createAnAccount: "Buat sebuah akun",
  nickName: "Nama panggilan",
  country: "Negara",
  signUp: "Mendaftar",
  createYourNickName: "Buat nama panggilan Anda",
  registrationFailedMessage: "Pendaftaran gagal, coba lagi nanti",

  // Logout Dialog
  logOutMessage: "Apakah Anda yakin ingin logout? Kemajuan Anda tidak akan disimpan",
  logOut: "Keluar",

  // Awards Page
  awardsMetaTitle: "Penghargaan Anda - Sudoku Pro",
  awardsMetaDescription: "Selesaikan tantangan harian di Sudoku Pro dan kumpulkan semua hadiah untuk tahun ini!",
  awards: "Penghargaan",
  yourAwards: "Penghargaan Anda",
  yourAwardsDescription: "Selesaikan tantangan harian dan dapatkan hadiah di akhir setiap bulan!",
  awardsDontMissASingleDay: "Jangan lewatkan satu hari pun - mainkan sudoku!",

  // Monthly Award Dialog
  awardDialogTitle: "Penghargaan Bulanan diterima",
  awardDialogDescription:
    "Anda telah menyelesaikan semua tantangan harian selama sebulan dan menerima penghargaan! Selamat!",
  awardShare: "Membagikan",

  // Authorization banner
  authorizationBannerMessage: "Masuk untuk menyimpan kemajuan dan penghargaan Anda 🏆",

  // You Won Dialog
  levelsLeftMessage: "Level tersisa untuk mendapatkan hadiah: %s",

  // Ratings Block
  topCountriesToday: "20 Negara TERATAS Saat Ini",
  topUsersToday: "100 Pengguna TERATAS Hari Ini",
  numberOfUsers: "Jumlah Pengguna",
  updatedEvery24hours: "Diperbarui setiap 24 jam",
  experiencePoints: "Poin pengalaman",
  showFullList: "Tampilkan daftar lengkap",
  showShortList: "Tampilkan daftar pendek",
};

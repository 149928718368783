export const rom: any = {
  ISOcode: "ro",
  countryName: "Romania",
  language: "Română",

  headerAndFooter: {
    moreGames: "Mai multe jocuri",
    playSudoku: "Joacă Sudoku",
    playSolitaire: "Joacă Solitaire",
    playMahjong: "Joacă Mahjong",
  },

  dailyChallenges: "Provocarea zilnică",
  dailyChallenge: "PROVOCARE ZILNICĂ",
  level: "Nivel: ",
  levels: "Niveluri",
  blog: "Blog",
  privacyPolicy: "Politica de confidențialitate",
  howToPlay: "Cum să joci",
  newGame: "JOC NOU",
  undo: "Anulează",
  notes: "Note",
  hints: "Sugestii",
  erase: "Ștergeți",
  nowDownload:
    "DESCĂRCAȚI ACUM DESCĂRCAȚI ACUM DESCĂRCAȚI ACUM DESCĂRCAȚI ACUM DESCĂRCAȚI ACUM DESCĂRCAȚI ACUM DESCĂRCAȚI ACUM DESCĂRCAȚI ACUM DESCĂRCAȚI ACUM DESCĂRCAȚI ACUM ",
  playBest: "Joacă cel mai bine",
  online: "Pe net",
  playSudoku: "Sudoku!",
  seeWeToldYou: "Vezi, ți-am spus că regulile sunt simple!",
  dontWasteYourTime: "Acum nu mai pierdeți timpul îndoindu-vă,",
  appIsSudokuPro: "cea mai bună aplicație Sudoku este Sudoku Pro și trebuie să o încercați!",
  playOnline: "juca online",
  points: "puncte",
  yes: "da",
  no: "Nu",
  playMahjong: "Joacă Mahjong online",
  siteMap: "Harta site-ului",
  otherGame: "Alte jocuri",
  historySudoku: "Istoria Sudoku",
  rulesSudoku: "Reguli pentru Sudoku",
  tipsSudoku: "Sfaturi pentru Sudoku",

  sudokuTipsAndTrick: "Sudoku online - sfaturi și trucuri, sau cum să devii un profesionist",
  goBack: "Înapoi la început",
  readBlog: "Citiți blogul",
  playSudokuOnline: "Joacă Sudoku online",
  playNow: "Joacă acum",
  cookieText:
    "Continuând să navigați pe site, confirmați că ați citit Politica de confidențialitate și sunteți de acord cu utilizarea cookie-urilor.",
  cancel: "Anulează",
  oops: "Uh-oh.",
  errorPageNotFound: "Eroare 404. Pagina nu a fost găsită",
  sorryGoBackHome:
    "Ne pare rău, pagina pe care ați solicitat-o nu a putut fi găsită. Vă rugăm să reveniți la pagina principală.",
  backToHomepage: "ÎNAPOI LA ACASĂ",
  similarArticles: "Similar Articles",
  startYourBrainTraining:
    "Începeți să vă dezvoltați creierul chiar acum! Descărcați jocul online gratuit Sudoku Pro și nu vă mai plângeți că nu vă puteți opri din rezolvarea puzzle-urilor Sudoku.",
  //////// Meta and Description /////////
  metaPageTitle: "Cum se joacă Sudoku - de la noob la Sudoku Pro",
  metaPageDescription:
    "Citiți blogul Sudoku Pro pentru sfaturi și trucuri despre cum să jucați Sudoku ca un profesionist, indiferent de nivelul dvs. acum. Obțineți o explicație simplă a strategiilor complexe ale jocului de logică pentru a juca mai eficient și pentru a vă îmbunătăți memoria.",

  metaBlogTitle: "Sudoku Online - sfaturi și trucuri | De la noob la Sudoku Pro",
  metaBlogDescription:
    "Citiți blogul nostru Sudoku Pro pentru a afla toate caracteristicile Sudoku pentru începători și jucători avansați. Obțineți o explicație simplă a strategiilor profesionale și sfaturi pentru a rezolva Sudoku online.",

  /////////////Basic rules/////////////
  gameRules: {
    subtitle: "Sudoku este ușor!",
    title: "Reguli de bază pentru a juca Sudoku",
    titleText:
      "Dacă ești începător și nu știi cum să rezolvi Sudoku, nu ai de ce să-ți fie rușine. Acest joc este foarte logic și este foarte ușor să înveți cum să joci Sudoku. Există 3 reguli de bază și, în funcție de tipul de joc de logică pe care îl alegeți, pot exista unele nuanțe, dar regulile de bază ale Sudoku rămân aceleași.",
    goal: "Obiectiv:",
    goalText:
      "Completați grila 9×9 astfel încât fiecare coloană, rând și secțiune 3×3 să conțină cifre de la 1 la 9. În mod tradițional, nu există o limită de timp, dar unele jocuri sudoku online adaugă o limită de timp și par să te împingă să gândești mai repede.",
    ruleTitle: "CUM SE JOACĂ SUDOKU?",
    ruleText:
      "Când începeți, unele pătrate din grila 9×9 vor avea deja numere. Sarcina ta este să completezi spațiile lipsă fără a lăsa spații goale.",
    fail: "AȚI EȘUAT DACĂ:",
    list: "Orice rând conține două numere identice <list> Orice coloană are două numere identice <list> Orice secțiune 3×3 are două numere identice",
    remember: "AMINTEȘTE-ȚI:",
    rememberText:
      "fiecare cifră de la 1 la 9 poate fi utilizată o singură dată într-un rând, într-o coloană sau într-o secțiune 3×3",
  },

  /////////// Months ////////
  January: "Ianuarie",
  February: "Februarie",
  March: "Martie",
  April: "Aprilie",
  May: "Mai",
  June: "Iunie",
  July: "Iulie",
  August: "August",
  September: "Septembrie",
  October: "Octombrie",
  November: "Noiembrie",
  December: "Decembrie",

  /////Win poup////
  youWin: "Victorie!",
  youWinText: "Descărcați aplicația pentru a juca oricând și fără restricții",
  followUs: "Urmăriți-ne pe rețelele de socializare:",
  winLevel: "Nivel",
  startNewGame: "Începeți un joc nou",
  time: "Timp",
  bestResult: "Cel mai bun scor",
  EASY: "Lumină",
  MEDIUM: "Mediu",
  HARD: "Greu",
  EXPERT: "Expert",
  EXTREME: "Extreme",
  /////////NoGame RC//////
  sorry: "scuze",
  gameNotFount: "joc nu a fost găsit",
  chooseChallenge: "Alegeți o provocare:",

  // Login Page
  loginMetaTitle: "Conectați-vă la Sudoku Pro",
  loginMetaDescription: "Conectează-te pentru a salva progresul jocului și a concura cu alți jucători",
  logIn: "Log in",
  welcomeBack: "Bine ai revenit",
  continueWithGoogle: "Continuați cu Google",
  continueWithFacebook: "Continuați cu Facebook",
  authorizationFailedMessage: "Autorizarea nu a reușit, încercați din nou mai târziu",

  // Registration Page
  registrationMetaTitle: "Finalizați înregistrarea - Sudoku Pro",
  registrationMetaDescription:
    "Mai rămâne un singur pas mic pentru a finaliza înregistrarea și a profita la maximum de jocul Sudoku",
  createAnAccount: "Creați un cont",
  nickName: "Poreclă",
  country: "Țară",
  signUp: "Inscrie-te",
  createYourNickName: "Creează-ți porecla",
  registrationFailedMessage: "Înregistrarea a eșuat, încercați din nou mai târziu",

  // Logout Dialog
  logOutMessage: "Sunteți sigur că doriți să vă deconectați? Progresul dvs. nu va fi salvat",
  logOut: "Deconectați-vă",

  // Awards Page
  awardsMetaTitle: "Premiile tale - Sudoku Pro",
  awardsMetaDescription: "Completați provocările zilnice în Sudoku Pro și colectați toate recompensele pentru anul!",
  awards: "Premii",
  yourAwards: "Premiile tale",
  yourAwardsDescription: "Completați provocările zilnice și obțineți recompense la sfârșitul fiecărei luni!",
  awardsDontMissASingleDay: "Nu rata nicio zi - joacă sudoku!",

  // Monthly Award Dialog
  awardDialogTitle: "Premiul lunar primit",
  awardDialogDescription: "Ai finalizat toate provocările zilnice timp de o lună și ai primit un premiu! Felicitări!",
  awardShare: "Acțiune",

  // Authorization banner
  authorizationBannerMessage: "Conectați-vă pentru a vă salva progresul și premiile 🏆",

  // You Won Dialog
  levelsLeftMessage: "Niveluri rămase pentru a obține recompensa: %s",

  // Ratings Block
  topCountriesToday: "TOP 20 de țări astăzi",
  topUsersToday: "TOP 100 de utilizatori astăzi",
  numberOfUsers: "Număr de utilizatori",
  updatedEvery24hours: "Actualizat la fiecare 24 de ore",
  experiencePoints: "Puncte de experiență",
  showFullList: "Afișează lista completă",
  showShortList: "Afișați o listă scurtă",
};

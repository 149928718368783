export const es: any = {
  ISOcode: "es",
  countryName: "Spain",
  language: "Español",

  headerAndFooter: {
    moreGames: "Más juegos",
    playSudoku: "Jugar sudokus",
    playSolitaire: "Jugar Solitario",
    playMahjong: "Jugar al Mahjong",
  },

  home: "Startseite",
  allow: "ermöglichen",
  blog: "Blog",
  dailyChallenges: "Desafíos diarios",
  dailyChallenge: "RETO DIARIO",
  readBlog: "Leer el blog",
  omisoft: "OmiSoft",
  sudokuPuzzleGame: "Sudoku Pro — Juego De Puzzle",
  sudokuTipsAndTrick: "Consejos y trucos para el sudoku kin — De novato a profesional del sudoku",
  oops: "Ooops...",
  errorPageNotFound: "Error 404. Página no encontrada tecnicas sudoku",
  sorryGoBackHome:
    "Lo sentimos, no se ha podido encontrar la página solicitada. Por favor, vuelva a la página de inicio Sudoku Online Gratis para jugar.",
  backToHomepage: "VOLVER A LA PÁGINA DE INICIO",
  cookieText:
    "Nuestro sitio web utiliza cookies para personalizar el contenido y analizar nuestro tráfico, lo que también puede dar lugar a la elaboración de perfiles.",
  ok: "OK",
  privacyPolicy: "Política de privacidad",
  howToPlay: "Cómo jugar",
  undo: "Deshacer",
  notes: "Observaciones",
  hints: "Consejos",
  erase: "Borrar",
  newGame: "nuevoJuego",
  level: "Nivel: ",
  levels: "Niveles",

  similarArticles: "Artículos similares",
  playSudokuOnline: "Jugar al Sudoku online",
  startYourBrainTraining:
    "Empieza a entrenar tu cerebro ahora, descarga el juego online gratuito Sudoku Pro, y no te quejes de que no puedes dejar de resolver.",
  nowDownload: "AHORA DESCARGA AHORA DESCARGA AHORA DESCARGA AHORA DESCARGA AHORA DESCARGA AHORA DESCARGA AHORA",
  playBest: "juega mejor",
  online: "En línea",
  playSudoku: "Sudoku!",
  seeWeToldYou: "Ves, ¡te dijimos que las reglas son fáciles!",
  dontWasteYourTime: "Ahora, no pierdas el tiempo dudando, la mejor aplicación ",
  appIsSudokuPro: "de sudokus imposibles es Sudoku Pro, ¡y debes probarla!",
  goBack: "Volver",
  facebook: "Facebook",
  twitter: "Twitter",
  telegram: "Telegram",
  points: "puntos",
  yes: "Sí",
  no: "No",
  playMahjong: "Jugar Mahjong en línea",
  siteMap: "Mapa del sitio",
  otherGame: "Otros juegos",
  historySudoku: "Historia del Sudoku",
  rulesSudoku: "Reglas para el Sudoku",
  tipsSudoku: "Consejos para sudokus",
  /////Win poup////
  youWin: "¡Tú ganas!",
  youWinText: "Descarga la aplicación para jugar en cualquier momento y sin restricciones.",
  followUs: "Síganos en las redes sociales:",
  winLevel: "Nivel",
  startNewGame: "Empezar un nuevo juego",
  time: "Tiempo",
  bestResult: "El mejor resultado",
  ////////////////////////
  playOnline: "jugar en línea",
  cancel: "Anular",

  //////// Meta and Description /////////

  metaPageTitle: "Cómo jugar al Sudoku — De Noob a SudokuPro",
  metaPageDescription:
    "Lee el blog de SudokuPro para aprender todos los consejos y trucos para jugar al Sudoku, tanto si eres un principiante absoluto como un jugador avanzado. Obtenga una explicación fácil de las estrategias profesionales para jugar más eficazmente y aumentar su salud cerebral",

  metaBlogTitle: "Consejos y trucos para el sudoku juegos online — De novato a profesional del sudoku",
  metaBlogDescription:
    "Lee nuestro blog para aprender todos los trucos del jugar Sudoku gratis online para principiantes y jugadores expertos. Obtenga una explicación sencilla de las estrategias y consejos profesionales para resolver el Sudoku.",

  //////Basic rules///////

  gameRules: {
    subtitle: "Sudoku fácil",
    title: "Reglas básicas del Sudoku",
    titleText:
      "Si eres un novato en el mundo del sudoku extremo gratis, no hay nada de qué avergonzarse. Este juego es muy lógico y fácil de conseguir. Hay 3 reglas básicas y dependiendo del tipo que elijas puede haber alteraciones, sin embargo, las reglas básicas establecidas rara vez cambian.",
    goal: "Objetivo:",
    goalText:
      "Rellenar una cuadrícula de 9×9, de modo que cada columna, fila y sección de 3×3 contenga los dígitos entre el 1 y el 9. Tradicionalmente no hay límite de tiempo, pero algunos juegos de Sudoku para el cerebro añaden limitaciones de tiempo que empujan a pensar más rápido.",
    ruleTitle: "¿Cómo se juega al sudoku online extremo?",
    ruleText:
      "Cuando empieces, algunas casillas de la cuadrícula de 9×9 ya tendrán dígitos. Tu tarea consiste en utilizar tu cerebro para rellenar los espacios que faltan y completar la cuadrícula.",
    fail: "Fallarás si:",
    list: "Cualquier fila tiene dos números iguales <list> Cualquier columna tiene dos números iguales <list> Cualquier cuadrícula 3×3 tiene dos números iguales",
    remember: "Recuerda:",
    rememberText: "Cada dígito del 1 al 9 puede utilizarse una sola vez en una fila, columna o cuadrícula de 3×3",
  },

  playNow: "SUDOKU JUGAR AHORA",
  /////////// Months ////////
  January: "Enero",
  February: "Febrero",
  March: "Marzo",
  April: "Abril",
  May: "Mayo",
  June: "Junio",
  July: "Julio",
  August: "Agosto",
  September: "Septiembre",
  October: "Octubre",
  November: "Noviembre",
  December: "Diciembre",
  EASY: "Fácil",
  MEDIUM: "Medio",
  HARD: "Hard",
  EXPERT: "Experto",
  EXTREME: "Extremo",
  /////////NoGame RC//////
  sorry: "perdón",
  gameNotFount: "Juego no encontrado",
  chooseChallenge: "Elige un reto aquí:",

  // Login Page
  loginMetaTitle: "Iniciar sesión en Sudoku Pro",
  loginMetaDescription: "Inicia sesión para guardar el progreso de tu juego y competir con otros jugadores.",
  logIn: "Acceso",
  welcomeBack: "Bienvenido de nuevo",
  continueWithGoogle: "Continuar con Google",
  continueWithFacebook: "Continuar con Facebook",
  authorizationFailedMessage: "La autorización falló, inténtalo de nuevo más tarde",

  // Registration Page
  registrationMetaTitle: "Finalizar registro - Sudoku Pro",
  registrationMetaDescription:
    "Ya sólo queda un pequeño paso para completar el registro y sacarle el máximo partido al juego de Sudoku",
  createAnAccount: "Crea una cuenta",
  nickName: "Apodo",
  country: "País",
  signUp: "Inscribirse",
  createYourNickName: "Crea tu apodo",
  registrationFailedMessage: "Error en el registro, inténtalo de nuevo más tarde",

  // Logout Dialog
  logOutMessage: "¿Está seguro de que desea cerrar sesión? Tu progreso no se guardará",
  logOut: "Cerrar sesión",

  // Awards Page
  awardsMetaTitle: "Tus premios - Sudoku Pro",
  awardsMetaDescription: "¡Completa los desafíos diarios en Sudoku Pro y recoge todas las recompensas del año!",
  awards: "Premios",
  yourAwards: "Tus premios",
  yourAwardsDescription: "¡Completa desafíos diarios y obtén recompensas al final de cada mes!",
  awardsDontMissASingleDay: "No te pierdas ni un solo día: ¡juega al sudoku!",

  // Monthly Award Dialog
  awardDialogTitle: "Premio mensual recibido",
  awardDialogDescription:
    "¡Has completado todos los desafíos diarios durante un mes y has recibido un premio! ¡Felicidades!",
  awardShare: "Compartir",

  // Authorization banner
  authorizationBannerMessage: "Inicia sesión para guardar tu progreso y premios 🏆",

  // You Won Dialog
  levelsLeftMessage: "Niveles restantes para obtener la recompensa: %s",

  // Ratings Block
  topCountriesToday: "TOP 20 países hoy",
  topUsersToday: "TOP 100 usuarios hoy",
  numberOfUsers: "Numero de usuarios",
  updatedEvery24hours: "Numerosos usuarios",
  experiencePoints: "Puntos de experiencia",
  showFullList: "Mostrar lista completa",
  showShortList: "Mostrar una lista corta",
};

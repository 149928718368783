export const tr: any = {
  ISOcode: "tr",
  countryName: "Turkey",
  language: "Türkçe",

  headerAndFooter: {
    moreGames: "Daha Fazla Oyun",
    playSudoku: "Sudoku Oyna",
    playSolitaire: "Solitaire Oyna",
    playMahjong: "Mahjong Oyna",
  },

  dailyChallenges: "Günlük Zorluklar",
  dailyChallenge: "GÜNLÜK MEYDAN OKUMA",
  level: "Seviye:",
  levels: "Seviyeler",
  blog: "Blog",
  privacyPolicy: "Gizlilik Politikası",
  howToPlay: "Nasıl oynanır",
  newGame: "YENİ OYUN",
  undo: "İptal",
  notes: "Notlar",
  hints: "İpuçları",
  erase: "Silme",
  nowDownload:
    "ŞIMDI INDIR ŞIMDI INDIR ŞIMDI INDIR ŞIMDI INDIR ŞIMDI INDIR ŞIMDI INDIR ŞIMDI INDIR ŞIMDI INDIR ŞIMDI INDIR ",
  playBest: "En iyiyi oyna",
  online: "Çevrimiçi",
  playSudoku: "Sudoku!",
  seeWeToldYou: "Gördünüz mü, size kuralların kolay olduğunu söylemiştik!",
  dontWasteYourTime: "Şimdi, şüphe ederek zamanınızı boşa harcamayın,",
  appIsSudokuPro: " en iyi sudoku uygulaması Sudoku Pro'dur ve mutlaka denemelisiniz!",
  playOnline: "online oyna",
  points: "puan",
  yes: "Evet",
  no: "HAYIR",
  playMahjong: "Mahjong'u Çevrimiçi Oyna",
  siteMap: "Site haritası",
  otherGame: "Diğer Oyunlar",
  historySudoku: "Sudoku Tarihi",
  rulesSudoku: "Sudoku Kuralları",
  tipsSudoku: "Sudoku için ipuçları",

  sudokuTipsAndTrick: "Sudoku İpuçları ve Püf Noktaları - Acemilikten SudokuPro'ya",
  goBack: "Geri dön",
  readBlog: "Blog Oku",
  playSudokuOnline: "Çevrimiçi Sudoku Oyna",
  playNow: "ŞİMDİ OYNA",
  cookieText:
    "Web sitemiz, içeriği kişiselleştirmek ve trafiğimizi analiz etmek için çerezler kullanır ve bu da profil oluşturmaya neden olabilir.",
  cancel: "İptal",
  oops: "Uh-oh.",
  errorPageNotFound: "404 hatası. Sayfa bulunamadı",
  sorryGoBackHome: "Üzgünüz, istediğiniz sayfa bulunamadı. Lütfen ana sayfaya dönün.",
  backToHomepage: "ANA SAYFAYA DÖN",
  similarArticles: "Similar Articles",
  startYourBrainTraining:
    "Beyin antrenmanınıza şimdi başlayın, ücretsiz çevrimiçi Sudoku Pro oyununu indirin ve çözmeden duramadığınız için şikayet etmeyin.",
  //////// Meta and Description /////////
  metaPageTitle: "Sudoku nasıl oynanır - acemilikten Sudoku Pro'ya",
  metaPageDescription:
    "Seviyeniz ne olursa olsun, Sudoku'yu bir profesyonel gibi nasıl oynayacağınıza dair ipuçları ve püf noktaları için Sudoku Pro blogunu okuyun. Daha verimli oynamak ve hafızanızı geliştirmek için mantık oyununun karmaşık stratejilerinin basit bir açıklamasını edinin.",

  metaBlogTitle: "Sudoku İpuçları ve Püf Noktaları - Acemilikten SudokuPro'ya",
  metaBlogDescription:
    "Yeni başlayanlar ve uzman oyuncular için Sudoku'daki tüm hileleri öğrenmek için blogumuzu okuyun. Sudoku çözmek için profesyonel stratejiler ve ipuçları hakkında kolay bir açıklama alın",

  /////////////Basic rules/////////////
  gameRules: {
    subtitle: "Kolay Sudoku",
    title: "Sudoku'nun temel kuralları",
    titleText:
      "Sudoku dünyasına yeni girdiyseniz, utanacak bir şey yok. Bu oyun çok mantıklı ve elde etmesi kolay. 3 temel kural vardır ve seçtiğiniz türe bağlı olarak değişiklikler olabilir, ancak temel kurallar seti nadiren değişir.",
    goal: "Hedef:",
    goalText:
      "Her bir sütun, satır ve 3×3 bölüm 1 ila 9 arasındaki rakamları içerecek şekilde 9×9'luk bir ızgarayı doldurmak. Geleneksel olarak zaman sınırlaması yoktur, ancak bazı Sudoku beyin oyunları daha hızlı düşünmeye iten zaman sınırlamaları ekler.",
    ruleTitle: "Sudoku nasıl oynanır?",
    ruleText:
      "Başladığınızda, 9×9 ızgaranın bazı karelerinde zaten rakamlar olacaktır. Göreviniz beyin gücünüzü kullanarak eksik noktaları doldurmak ve tabloyu tamamlamaktır.",
    fail: "Eğer başarısız olursanız:",
    list: "Herhangi bir satırda iki aynı sayı varsa <list> Herhangi bir sütunda iki aynı sayı varsa <list> Herhangi bir 3×3 ızgarada iki aynı sayı varsa",
    remember: "Unutmayın:",
    rememberText: "1'den 9'a kadar her rakam bir satırda, sütunda veya 3×3 ızgarada yalnızca bir kez kullanılabilir",
  },

  /////////// Months ////////
  January: "Ocak",
  February: "Şubat",
  March: "Mart",
  April: "Nisan",
  May: "Mayıs",
  June: "Haziran",
  July: "Temmuz",
  August: "Ağustos",
  September: "Eylül",
  October: "Ekim",
  November: "Kasım",
  December: "Aralık",

  /////Win poup////
  youWin: "Zafer!",
  youWinText: "İstediğiniz zaman ve herhangi bir kısıtlama olmadan oynamak için uygulamayı indirin",
  followUs: "Bizi sosyal ağlarda takip edin:",
  winLevel: "Seviye",
  startNewGame: "Yeni bir oyun başlatın",
  time: "Zaman",
  bestResult: "En iyi skor",
  EASY: "Kolay",
  MEDIUM: "Orta",
  HARD: "Sert",
  EXPERT: "Uzman",
  EXTREME: "Aşırı",
  /////////NoGame RC//////
  sorry: "Üzgünüm",
  gameNotFount: "oyun bulunamadı",
  chooseChallenge: "Buradan bir meydan okuma seçin:",
};

export const ka: any = {
  ISOcode: "ka",
  countryName: "საქართველოს",
  language: "ქართული",

  headerAndFooter: {
    moreGames: "მეტი თამაშები",
    playSudoku: "ითამაშეთ სუდოკუ",
    playSolitaire: "ითამაშეთ Solitaire",
    playMahjong: "დაითვალიერეთ მაჟონგი",
  },

  blog: "ბლოგი",
  dailyChallenges: "ყოველდღიური გამოწვევები",
  dailyChallenge: "ყოველდღიური გამოწვევა",
  readBlog: "წაიკითხეთ ბლოგი",
  omisoft: "OmiSoft",
  sudokuPuzzleGame: "Sudoku Pro — თავსატეხი",
  sudokuTipsAndTrick: "სუდოკუ ონლაინ - რჩევები და ხრიკები, ან როგორ გახდეთ პროფესიონალი",
  oops: "უი...",
  errorPageNotFound: "404 შეცდომა. გვერდი ვერ მოიძებნა",
  sorryGoBackHome: "ვწუხვართ, მაგრამ თქვენ მიერ მოთხოვნილი გვერდი ვერ მოიძებნა. გთხოვთ დაბრუნდეთ მთავარ გვერდზე.",
  backToHomepage: "დაბრუნება მთავარ გვერდზე",
  cookieText:
    "ჩვენი ვებსაიტი იყენებს ქუქი-ფაილებს კონტენტის პერსონალიზებისთვის და ჩვენი ტრაფიკის გასაანალიზებლად, რამაც შეიძლება ასევე გამოიწვიოს პროფილირება.",
  ok: "კარგი",
  privacyPolicy: "კონფიდენციალურობა",
  howToPlay: "როგორ ითამაშოს",
  undo: "გაუქმება",
  notes: "შენიშვნები",
  hints: "მინიშნებები",
  erase: "წაშლა",
  newGame: "ახალი თამაში",
  level: "დონე: ",
  levels: "დონეები",
  similarArticles: "მსგავსი სტატიები",
  playSudokuOnline: "ითამაშეთ Sudoku ონლაინ",
  startYourBrainTraining:
    "დაიწყეთ თქვენი ტვინის ვარჯიში ახლავე, ჩამოტვირთეთ უფასო ონლაინ თამაში Sudoku Pro და არ იწუწუნოთ, რომ ვერ შეწყვეტთ ამოხსნას.",
  nowDownload: "ახლა ჩამოტვირთე ახლა ჩამოტვირთე ახლა ჩამოტვირთე ახლა ჩამოტვირთე ახლა ჩამოტვირთე",
  playBest: "ითამაშეთ საუკეთესოდ",
  online: "ონლაინ",
  playSudoku: "სუდოკუ!",
  seeWeToldYou: "ნახეთ, ჩვენ გითხარით, რომ წესები მარტივია!",
  dontWasteYourTime: "ახლა, ნუ დაკარგავთ დროს ეჭვის შეტანაში, სუდოკუს საუკეთესო",
  appIsSudokuPro: "აპლიკაცია არის Sudoku Pro და თქვენ უნდა სცადოთ იგი!",
  goBack: "უკან დაბრუნდი",
  facebook: "Facebook",
  twitter: "Twitter",
  telegram: "Telegram",
  points: "ქულები",
  yes: "დიახ",
  no: "არა",
  playMahjong: "ითამაშეთ Mahjong ონლაინ",
  siteMap: "საიტის რუკა",
  otherGame: "სხვა თამაშები",
  historySudoku: "Sსუდოკუს ისტორია",
  rulesSudoku: "სუდოკუს წესები",
  tipsSudoku: "რჩევები სუდოკუსთვის",
  /////Win poup////
  youWin: "შენ გაიმარჯვე!",
  youWinText: "ჩამოტვირთეთ აპი ნებისმიერ დროს სათამაშოდ რამდენიმე უპირატესობით",
  followUs: "გამოგვყევით სოციალურ მედიაში:",
  winLevel: "დონე",
  startNewGame: "დაიწყე ახალი თამაში",
  time: "დრო",
  bestResult: "საუკეთესო შედეგი",
  ////////////////////////
  playOnline: "ითამაშეთ ონლაინ",
  cancel: "გაუქმება",
  //////// Meta and Description /////////
  metaPageTitle: "როგორ ვითამაშოთ სუდოკუ — Noob-დან SudokuPro-მდე",
  metaPageDescription:
    "წაიკითხეთ SudokuPro ბლოგი, რომ გაიგოთ სუდოკუს თამაშის ყველა რჩევა და ხრიკი, მიუხედავად იმისა, ხართ თუ არა აბსოლუტური დამწყები თუ მოწინავე მოთამაშე. მიიღეთ პრო სტრატეგიების მარტივი ახსნა, რომ უფრო ეფექტურად ითამაშოთ და გაზარდოთ თქვენი ტვინის ჯანმრთელობა",

  metaBlogTitle: "სუდოკუს რჩევები და ხრიკები — დამწყებიდან SudokuPro-მდე",
  metaBlogDescription:
    "წაიკითხეთ ჩვენი ბლოგი, რომ გაიგოთ სუდოკუს ყველა ხრიკი დამწყებთათვის და გამოცდილი მოთამაშეებისთვის. მიიღეთ მარტივი ახსნა პრო სტრატეგიებისა და რჩევების შესახებ სუდოკუს გადასაჭრელად",

  //////Basic rules///////

  gameRules: {
    subtitle: "სუდოკუ გაადვილდა",
    title: "სუდოკუს ძირითადი წესები",
    titleText:
      "თუ სუდოკუს სამყაროში დამწყები ხართ, არაფერია სასირცხვილო. ეს თამაში არის ძალიან ლოგიკური და ადვილად მოსაპოვებელი. არსებობს 3 ძირითადი წესი და თქვენს მიერ არჩეული ტიპის მიხედვით შეიძლება იყოს ცვლილებები, თუმცა, დაწესებული ძირითადი წესები იშვიათად იცვლება.",
    goal: "მიზანი:",
    goalText:
      "9×9 ბადის შევსება ისე, რომ ყოველი სვეტი, მწკრივი და 3×3 სექცია შეიცავდეს ციფრებს 1-დან 9-მდე. ტრადიციულად არ არსებობს დროის ლიმიტი, მაგრამ ზოგიერთი სუდოკუს თამაში ტვინისთვის ამატებს დროის შეზღუდვებს, რაც უბიძგებს უფრო სწრაფად იფიქროს.",
    ruleTitle: "როგორ ვითამაშოთ სუდოკუ?",
    ruleText:
      "როდესაც დაიწყებთ, 9×9 ბადის ზოგიერთ კვადრატს უკვე ექნება ციფრები. შენი ამოცანაა გამოიყენო შენი გონების ძალა გამოტოვებული ადგილების შესავსებად და ბადის დასასრულებლად.",
    fail: "თქვენ მარცხდებით, თუ:",
    list: "ნებისმიერ მწკრივს აქვს ორი იგივე რიცხვი < სია> ნებისმიერ სვეტს აქვს ორი იგივე რიცხვი < სია> ნებისმიერ 3×3 ბადეს აქვს ორი იგივე რიცხვი",
    remember: "გახსოვდეთ:",
    rememberText: "თითოეული ციფრი 1-დან 9-მდე შეიძლება გამოყენებულ იქნას მხოლოდ ერთხელ ზედიზედ, სვეტში ან 3×3 ბადეში",
  },

  playNow: "ითამაშეთ ახლა",
  /////////// Months ////////
  January: "იანვარი",
  February: "თებერვალი",
  March: "მარტი",
  April: "აპრილი",
  May: "მაისი",
  June: "ივნისი",
  July: "ივლისი",
  August: "აგვისტო",
  September: "სექტემბერი",
  October: "ოქტომბერი",
  November: "ნოემბერი",
  December: "დეკემბერი",
  ///////// Game levels //////
  EASY: "ადვილად",
  MEDIUM: "საშუალო",
  HARD: "მძიმე",
  EXPERT: "ექსპერტი",
  EXTREME: "ექსტრემალური",
  /////////NoGame RC//////
  sorry: "ბოდიში",
  gameNotFount: "თამაში ვერ მოიძებნა",
  chooseChallenge: "აირჩიეთ გამოწვევა აქ:",

  // Login Page
  loginMetaTitle: "შედით Sudoku Pro-ში",
  loginMetaDescription: "შედით სისტემაში, რათა შეინახოთ თქვენი თამაშის პროგრესი და შეეჯიბროთ სხვა მოთამაშეებს",
  logIn: "Შესვლა",
  welcomeBack: "Კეთილი იყოს თქვენი დაბრუნება",
  continueWithGoogle: "განაგრძეთ Google-თან",
  continueWithFacebook: "განაგრძეთ Facebook-თან ერთად",
  authInfoModalTitleFailed: "ავტორიზაცია ვერ მოხერხდა",
  authorizationFailedMessage: "ავტორიზაცია ვერ მოხერხდა, სცადეთ მოგვიანებით",

  // Registration Page
  registrationMetaTitle: "დაასრულეთ რეგისტრაცია - Sudoku Pro",
  registrationMetaDescription:
    "დარჩენილია მხოლოდ ერთი პატარა ნაბიჯი რეგისტრაციის დასასრულებლად და სუდოკუს თამაშიდან მაქსიმალური სარგებლობის მისაღებად",
  createAnAccount: "Შექმენით ანგარიში",
  nickName: "მეტსახელი",
  country: "ქვეყანა",
  signUp: "დარეგისტრირდით",
  createYourNickName: "შექმენით თქვენი მეტსახელი",
  authInfoModalRegistrationTitleErrors: "რეგისტრაცია ვერ მოხერხდა",
  registrationFailedMessage: "რეგისტრაცია ვერ მოხერხდა, სცადეთ მოგვიანებით",

  // Logout Dialog
  logOutMessage: "დარწმუნებული ხართ, რომ გსურთ გამოსვლა? თქვენი პროგრესი არ შეინახება",
  logOut: "გასვლა",

  // Awards Page
  awardsMetaTitle: "თქვენი ჯილდოები - Sudoku Pro",
  awardsMetaDescription: "დაასრულეთ ყოველდღიური გამოწვევები Sudoku Pro-ში და შეაგროვეთ წლის ყველა ჯილდო!",
  awards: "Ჯილდო",
  yourAwards: "შენი ჯილდოები",
  yourAwardsDescription: "დაასრულეთ ყოველდღიური გამოწვევები და მიიღეთ ჯილდოები ყოველი თვის ბოლოს!",
  awardsDontMissASingleDay: "არ გამოტოვოთ არც ერთი დღე - ითამაშეთ სუდოკუ!",
  getAwards: "მიიღეთ ჯილდოები!",

  // Monthly Award Dialog
  awardDialogTitle: "ყოველთვიური ჯილდო მიღებული",
  awardDialogDescription:
    "თქვენ დაასრულეთ ყველა ყოველდღიური გამოწვევა ერთი თვის განმავლობაში და მიიღეთ ჯილდო! გილოცავ!",
  awardShare: "გააზიარეთ",

  // Authorization banner
  authorizationBannerMessage: "შედით თქვენი პროგრესისა და ჯილდოების შესანახად 🏆",

  // You Won Dialog
  levelsLeftMessage: "🏆ჯილდოს მისაღებად დარჩენილი დონეები: %s",
  finalPoints: "Final points",

  // Ratings Block
  topCountriesToday: "ტოპ 20 ქვეყანა დღეს",
  topUsersToday: "ტოპ 100 მომხმარებელი დღეს",
  numberOfUsers: "მომხმარებელთა რაოდენობა",
  updatedEvery24hours: "განახლებულია ყოველ 24 საათში",
  experiencePoints: "გამოცდილების ქულები",
  showFullList: "სრული სიის ჩვენება",
  showShortList: "მოკლე სიის ჩვენება",

  // ControlHintsModalWarning
  controlHintsModalWarningMessage: "ეს ფუნქცია მიუწვდომელია ყოველდღიურ გამოწვევებში",

  // ConsecutiveDaysWarningModal
  consecutiveDaysModalText: "ზედიზედ თამაშის დღეების რაოდენობა. ითამაშეთ ყოველდღე ამ მიღწევის გასაუმჯობესებლად!",
  consecutiveDaysModalTextLogIn: "შედით თქვენი მიღწევების შესანახად",
};

export const sk: any = {
  ISOcode: "sk",
  countryName: "Slovak",
  language: "Slovenčina",

  headerAndFooter: {
    moreGames: "Viac hier",
    playSudoku: "Hrajte Sudoku",
    playSolitaire: "Hrajte Solitaire",
    playMahjong: "Hrať Mahjong",
  },

  dailyChallenges: "Denné výzvy",
  dailyChallenge: "V TEJTO PONUKE NÁJDETE: DENNÚ VÝZVU",
  level: "Úroveň: ",
  levels: "Úrovne",
  blog: "Blog",
  privacyPolicy: "Zásady ochrany osobných údajov",
  howToPlay: "Ako hrať",
  newGame: "NOVÁ HRA",
  undo: "Zrušiť",
  notes: "Poznámky",
  hints: "Tipy",
  erase: "Vymazať",
  nowDownload:
    "TERAZ STIAHNUŤ TERAZ STIAHNUŤ TERAZ STIAHNUŤ TERAZ STIAHNUŤ TERAZ STIAHNUŤ TERAZ STIAHNUŤ TERAZ STIAHNUŤ TERAZ STIAHNUŤ TERAZ STIAHNUŤ ",
  playBest: "Hrajte najlepšie",
  online: "Online",
  playSudoku: "Sudoku!",
  seeWeToldYou: "Vidíte, hovorili sme vám, že pravidlá sú jednoduché!",
  dontWasteYourTime: "Teraz už nestrácajte čas pochybnosťami,",
  appIsSudokuPro: " najlepšia aplikácia sudoku je Sudoku Pro a musíte ju vyskúšať!",
  playOnline: "hrať online",
  sudokuTipsAndTrick: "Sudoku tipy a triky - Od nooba po SudokuPro",
  goBack: "Späť",
  readBlog: "Prečítajte si blog",
  playSudokuOnline: "Zahrajte si sudoku online",
  playNow: "HRAJTE TERAZ",
  points: "bodov",
  yes: "Áno",
  no: "Nie",
  playMahjong: "Zahrajte si Mahjong online",
  siteMap: "Mapa stránok",
  otherGame: "Iné hry",
  historySudoku: "História sudoku",
  rulesSudoku: "Pravidlá pre sudoku",
  tipsSudoku: "Tipy pre sudoku",
  cookieText:
    "Naše webové stránky používajú súbory cookie na prispôsobenie obsahu a analýzu návštevnosti, čo môže viesť aj k profilovaniu.",
  cancel: "Zrušiť",
  oops: "Ooops...",
  errorPageNotFound: "Chyba 404. Stránka nebola nájdená",
  sorryGoBackHome:
    "Je nám ľúto, ale stránku, ktorú ste požadovali, sa nepodarilo nájsť. Vráťte sa prosím na domovskú stránku.",
  backToHomepage: "SPÄŤ NA DOMOVSKÚ STRÁNKU",
  similarArticles: "Podobné články",
  startYourBrainTraining:
    "Začnite trénovať svoj mozog teraz, stiahnite si bezplatnú online hru Sudoku Pro a nesťažujte sa, že nemôžete prestať riešiť.",
  //////// Meta and Description /////////
  metaPageTitle: "Ako hrať Sudoku - od začiatočníka po SudokuPro",
  metaPageDescription:
    "Prečítajte si blog SudokuPro a dozviete sa všetky tipy a triky na hranie Sudoku bez ohľadu na to, či ste úplný začiatočník alebo pokročilý hráč. Získajte jednoduché vysvetlenie profesionálnych stratégií na efektívnejšie hranie a posilnenie zdravia vášho mozgu",
  metaBlogTitle: "Tipy a triky v sudoku - od nooba po SudokuPro",
  metaBlogDescription:
    "Prečítajte si náš blog a naučte sa všetky triky v hre Sudoku pre začiatočníkov a skúsených hráčov. Získajte jednoduché vysvetlenie profesionálnych stratégií a tipov na riešenie Sudoku",
  /////////////Basic rules/////////////
  gameRules: {
    subtitle: "Jednoduché sudoku",
    title: "Základné pravidlá hry Sudoku",
    titleText:
      "Ak ste vo svete Sudoku nováčikom, nemusíte sa za to hanbiť. Táto hra je veľmi logická a ľahko sa do nej dostanete. Existujú 3 základné pravidlá a v závislosti od typu, ktorý si vyberiete, môže dôjsť k zmenám, avšak základný súbor pravidiel sa mení len zriedka.",
    goal: "Cieľ:",
    goalText:
      "Vyplňte mriežku 9×9 tak, aby každý stĺpec, riadok a výsek 3×3 obsahoval číslice od 1 do 9. Tradične neexistuje žiadny časový limit, ale niektoré hry Sudoku pre mozog pridávajú časové obmedzenia, ktoré tlačia na rýchlejšie premýšľanie.",
    ruleTitle: "Ako hrať Sudoku?",
    ruleText:
      "Keď začnete, niektoré štvorce mriežky 9×9 už budú obsahovať číslice. Vašou úlohou je pomocou mozgovej kapacity doplniť chýbajúce miesta a dokončiť mriežku.",
    fail: "Ak sa vám to nepodarí:",
    list: "ľubovoľný riadok obsahuje dve rovnaké čísla <list> ľubovoľný stĺpec obsahuje dve rovnaké čísla <list> ľubovoľná mriežka 3×3 obsahuje dve rovnaké čísla",
    remember: "Zapamätajte si:",
    rememberText: "Každá číslica od 1 do 9 môže byť v riadku, stĺpci alebo mriežke 3×3 použitá len raz.",
  },
  /////////// Months ////////
  January: "Január",
  February: "Február",
  March: "Marec",
  April: "Apríl",
  May: "Máj",
  June: "Jún",
  July: "Júl",
  August: "August",
  September: "September",
  October: "Október",
  November: "November",
  December: "December",
  /////Win poup////
  youWin: "Vyhrali ste!!",
  youWinText: "Stiahnite si aplikáciu a hrajte kedykoľvek a bez akýchkoľvek obmedzení",
  followUs: "Sledujte nás na sociálnych sieťach:",
  winLevel: "Úroveň",
  startNewGame: "Spustiť novú hru",
  time: "Čas",
  bestResult: "Najlepší výsledok",
  EASY: "Lahké",
  MEDIUM: "Stredná",
  HARD: "Ťažké",
  EXPERT: "Expert",
  EXTREME: "Extrémne",
  /////////NoGame RC//////
  sorry: "Prepáčte",
  gameNotFount: "hra nebola nájdená",
  chooseChallenge: "Vyberte si výzvu tu:",

  // Login Page
  loginMetaTitle: "Prihláste sa do Sudoku Pro",
  loginMetaDescription: "Prihláste sa, aby ste si uložili postup v hre a súťažili s ostatnými hráčmi",
  logIn: "Prihlásiť sa",
  welcomeBack: "Vitaj späť",
  continueWithGoogle: "Pokračujte so službou Google",
  continueWithFacebook: "Pokračujte na Facebooku",
  authorizationFailedMessage: "Autorizácia zlyhala, skúste to znova neskôr",

  // Registration Page
  registrationMetaTitle: "Dokončiť registráciu - Sudoku Pro",
  registrationMetaDescription:
    "K dokončeniu registrácie a maximálnemu využitiu hry Sudoku zostáva už len jeden malý krok",
  createAnAccount: "Vytvoriť účet",
  nickName: "prezývka",
  country: "Krajina",
  signUp: "Prihlásiť Se",
  createYourNickName: "Vytvorte si prezývku",
  registrationFailedMessage: "Registrácia zlyhala, skúste to znova neskôr",

  // Logout Dialog
  logOutMessage: "Naozaj sa chcete odhlásiť? Váš postup sa neuloží",
  logOut: "Odhlásiť sa",

  // Awards Page
  awardsMetaTitle: "Vaše ocenenia - Sudoku Pro",
  awardsMetaDescription: "Dokončite každodenné výzvy v hre Sudoku Pro a pozbierajte všetky odmeny za rok!",
  awards: "ocenenia",
  yourAwards: "Vaše ocenenia",
  yourAwardsDescription: "Dokončite každodenné výzvy a na konci každého mesiaca získajte odmeny!",
  awardsDontMissASingleDay: "Nepremeškajte ani jeden deň – hrajte sudoku!",

  // Monthly Award Dialog
  awardDialogTitle: "Získaná mesačná cena",
  awardDialogDescription: "Splnili ste všetky denné výzvy za mesiac a získali ste ocenenie! Gratulujem!",
  awardShare: "zdieľam",

  // Authorization banner
  authorizationBannerMessage: "Prihláste sa a uložte si svoj postup a ocenenia 🏆",

  // You Won Dialog
  levelsLeftMessage: "Zostávajúce úrovne na získanie odmeny: %s",

  // Ratings Block
  topCountriesToday: "TOP 20 krajín dneška",
  topUsersToday: "TOP 100 používateľov dneška",
  numberOfUsers: "Počet používateľov",
  updatedEvery24hours: "Aktualizované každých 24 hodín",
  experiencePoints: "Skúsenostné body",
  showFullList: "Zobraziť celý zoznam",
  showShortList: "Ukážte krátky zoznam",
};

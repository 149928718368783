export const fi: any = {
  ISOcode: "fi",
  countryName: "Finland",
  language: "Suomi",

  headerAndFooter: {
    moreGames: "Lisää pelejä",
    playSudoku: "Pelaa Sudokua",
    playSolitaire: "Pelaa pasianssia",
    playMahjong: "Pelaa Mahjongia",
  },

  dailyChallenges: "Päivittäiset haasteet",
  dailyChallenge: "PÄIVITTÄINEN HAASTE",
  level: "Taso: ",
  levels: "Tasot",
  blog: "Blogi",
  privacyPolicy: "Tietosuojakäytäntö",
  howToPlay: "Kuinka pelata",
  newGame: "UUSI PELI",
  undo: "Kumoa",
  notes: "Notes",
  hints: "Vihjeet",
  erase: "Poista",
  nowDownload:
    "LATAA NYT LATAA NYT LATAA NYT LATAA NYT LATAA NYT LATAA NYT LATAA NYT LATAA NYT LATAA NYT LATAA NYT LATAA NYT LATAA NYT LATAA NYT ",
  playBest: "Pelaa parhaiten",
  online: "verkossa",
  playSudoku: "Sudoku!",
  seeWeToldYou: "Näetkö, sanoimme, että säännöt ovat helppoja!",
  dontWasteYourTime: "Älä tuhlaa aikaasi epäilemällä, paras sudoku-sovellus ",
  appIsSudokuPro: "on Sudoku Pro, ja sinun täytyy kokeilla sitä!",
  playOnline: "pelata verkossa",
  points: "pisteitä",
  yes: "Joo",
  no: "Ei",
  playMahjong: "Pelaa Mahjongia verkossa",
  siteMap: "Site haritası",
  otherGame: "Diğer Oyunlar",
  historySudoku: "Sudokun historia",
  rulesSudoku: "Sudokun säännöt",
  tipsSudoku: "Vinkkejä Sudokuun",

  sudokuTipsAndTrick: "Sudoku vinkkejä ja temppuja - Noobista SudokuPro:hon",
  goBack: "Palaa takaisin",
  readBlog: "Lue blogi",
  playSudokuOnline: "Pelaa sudokua verkossa",
  playNow: "PELAA NYT",
  cookieText:
    "Verkkosivustomme käyttää evästeitä sisällön personoimiseksi ja liikenteen analysoimiseksi, mikä voi myös johtaa profilointiin.",
  cancel: "Peruuta",
  oops: "Hups...",
  errorPageNotFound: "404 virhe. Sivua ei löydy",
  sorryGoBackHome: "Olemme pahoillamme, pyytämääsi sivua ei löytynyt. Palaa takaisin etusivulle.",
  backToHomepage: "TAKAISIN ETUSIVULLE",
  similarArticles: "Samankaltaisia artikkeleita",
  startYourBrainTraining:
    "Aloita aivojumppa nyt, lataa ilmainen nettipeli Sudoku Pro, äläkä valita, ettet voi lopettaa ratkaisemista.",
  //////// Meta and Description /////////
  metaPageTitle: "Kuinka pelata Sudokua - Noobista SudokuPro:ksi",
  metaPageDescription:
    "Lue SudokuPro-blogi ja opi kaikki vinkit ja niksit Sudokun pelaamiseen riippumatta siitä, oletko aivan aloittelija vai edistynyt pelaaja. Saat helpon selityksen pro-strategioista, joiden avulla voit pelata tehokkaammin ja parantaa aivojesi terveyttä.",

  metaBlogTitle: "Sudoku vinkkejä ja temppuja - Noobista SudokuPro:ksi",
  metaBlogDescription:
    "Lue blogiamme oppiaksesi kaikki Sudokun niksit aloittelijoille ja kokeneille pelaajille. Saat helpon selityksen pro-strategioista ja vinkkejä Sudokun ratkaisemiseen.",

  /////////////Basic rules/////////////
  gameRules: {
    subtitle: "Sudoku helpoksi tehty",
    title: "Sudokun perussäännöt",
    titleText:
      "Jos olet aloittelija Sudokun maailmassa, sinun ei tarvitse hävetä mitään. Tämä peli on hyvin looginen ja helppo saada. Perussääntöjä on 3 ja valitusta tyypistä riippuen niihin saattaa tulla muutoksia, mutta perussäännöt muuttuvat harvoin.",
    goal: "Tavoite:",
    goalText:
      "Täyttää 9×9-ruudukko niin, että jokainen sarake, rivi ja 3×3-osa sisältää numerot välillä 1-9. Perinteisesti aikarajoitusta ei ole, mutta joihinkin aivoille suunnattuihin Sudoku-peleihin on lisätty aikarajoituksia, jotka pakottavat ajattelemaan nopeammin.",
    ruleTitle: "Miten Sudokua pelataan?",
    ruleText:
      "Kun aloitat, joissakin 9×9-ruudun ruuduissa on jo numeroita. Tehtäväsi on käyttää aivojasi täyttämään puuttuvat kohdat ja täydentämään ruutu.",
    fail: "Epäonnistut, jos:",
    list: "Millä tahansa rivillä on kaksi samaa numeroa <list> Missä tahansa sarakkeessa on kaksi samaa numeroa <list> Missä tahansa 3×3-ruudukossa on kaksi samaa numeroa.",
    remember: "Muista:",
    rememberText: "Jokaista numeroa 1-9 voidaan käyttää vain kerran rivillä, sarakkeessa tai 3×3-ruudukossa.",
  },

  /////////// Months ////////
  January: "Tammikuu",
  February: "Helmikuu",
  March: "Maaliskuu",
  April: "Huhtikuu",
  May: "Toukokuu",
  June: "Kesäkuu",
  July: "Heinäkuu",
  August: "Elokuu",
  September: "Syyskuu",
  October: "Lokakuu",
  November: "Marraskuu",
  December: "Joulukuu",

  /////Win poup////
  youWin: "Olet voittanut!!",
  youWinText: "Lataa sovellus pelataksesi milloin tahansa ja ilman rajoituksia",
  followUs: "Seuraa meitä sosiaalisessa mediassa:",
  winLevel: "Taso",
  startNewGame: "Aloita uusi peli",
  time: "Aika",
  bestResult: "Paras tulos",
  EASY: "Helppo",
  MEDIUM: "Keskisuuri",
  HARD: "Vaikea",
  EXPERT: "Asiantuntija",
  EXTREME: "Extreme",
  ///////NoGame RC//////
  sorry: "anteeksi",
  gameNotFount: "peliä ei löydy",
  chooseChallenge: "Valitse haaste täältä:",

  // Login Page
  loginMetaTitle: "Kirjaudu Sudoku Prohon",
  loginMetaDescription: "Kirjaudu sisään tallentaaksesi pelin edistymisen ja kilpaillaksesi muiden pelaajien kanssa",
  logIn: "Kirjaudu sisään",
  welcomeBack: "Tervetuloa takaisin",
  continueWithGoogle: "Jatka Googlella",
  continueWithFacebook: "Jatka Facebookissa",
  authorizationFailedMessage: "Valtuutus epäonnistui, yritä myöhemmin uudelleen",

  // Registration Page
  registrationMetaTitle: "Viimeistele rekisteröinti - Sudoku Pro",
  registrationMetaDescription:
    "Rekisteröinnin suorittamiseen ja Sudoku-pelistä kaiken irti on jäljellä vain yksi pieni vaihe",
  createAnAccount: "Luo tili",
  nickName: "Nimimerkki",
  country: "Maa",
  signUp: "Kirjaudu",
  createYourNickName: "Luo lempinimesi",
  registrationFailedMessage: "Rekisteröinti epäonnistui, yritä myöhemmin uudelleen",

  // Logout Dialog
  logOutMessage: "Haluatko varmasti kirjautua ulos? Edistymistäsi ei tallenneta",
  logOut: "Kirjautua ulos",

  // Awards Page
  awardsMetaTitle: "Sinun palkintosi - Sudoku Pro",
  awardsMetaDescription: "Suorita päivittäiset haasteet Sudoku Prossa ja kerää kaikki vuoden palkinnot!",
  awards: "Palkinnot",
  yourAwards: "Sinun palkintosi",
  yourAwardsDescription: "Suorita päivittäiset haasteet ja hanki palkintoja jokaisen kuukauden lopussa!",
  awardsDontMissASingleDay: "Älä missaa päivääkään – pelaa sudokua!",

  // Monthly Award Dialog
  awardDialogTitle: "Kuukausipalkinto vastaanotettu",
  awardDialogDescription:
    "Olet suorittanut kaikki päivittäiset haasteet kuukauden ajan ja saanut palkinnon! Onnittelut!",
  awardShare: "Jaa",

  // Authorization banner
  authorizationBannerMessage: "Kirjaudu sisään tallentaaksesi edistymisesi ja palkintosi 🏆",

  // You Won Dialog
  levelsLeftMessage: "Tasoja jäljellä saadaksesi palkinnon: %s",

  // Ratings Block
  topCountriesToday: "TOP 20 maata tänään",
  topUsersToday: "TOP 100 käyttäjää tänään",
  numberOfUsers: "Käyttäjien lukumäärä",
  updatedEvery24hours: "Päivitetään 24 tunnin välein",
  experiencePoints: "Kokemuspisteitä",
  showFullList: "Näytä koko luettelo",
  showShortList: "Näytä lyhyt luettelo",
};

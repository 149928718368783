export const da: any = {
  ISOcode: "da",
  countryName: "Danish",
  language: "Dansk",

  headerAndFooter: {
    moreGames: "Flere spil",
    playSudoku: "Spil Sudoku",
    playSolitaire: "Spil Solitaire",
    playMahjong: "Spil Mahjong",
  },

  dailyChallenges: "Daglige udfordringer",
  dailyChallenge: "VÆLG EN DAGLIG UDFORDRING",
  level: "Niveau: ",
  levels: "Niveauer",
  blog: "Blog",
  privacyPolicy: "Fortrolighedspolitik",
  howToPlay: "Hvordan man spiller",
  newGame: "NYT SPIL",
  undo: "Fortryd",
  notes: "Noter",
  hints: "Tips",
  erase: "Slet",
  nowDownload:
    "NU DOWNLOAD NU DOWNLOAD NU DOWNLOAD NU DOWNLOAD NU DOWNLOAD NU DOWNLOAD NU DOWNLOAD NU DOWNLOAD NU DOWNLOAD NU DOWNLOAD NU DOWNLOAD NU ",
  playBest: "Spil bedst",
  online: "Online",
  playSudoku: "Sudoku!",
  seeWeToldYou: "Se, vi sagde jo, at reglerne er nemme!",
  dontWasteYourTime: "Spild nu ikke din tid på at tvivle, ",
  appIsSudokuPro: "den bedste sudoku-app er Sudoku Pro, og du skal prøve den!",
  playOnline: "spille online",
  points: "point",
  yes: "Ja",
  no: "Ingen",
  playMahjong: "Spil Mahjong online",
  siteMap: "Site map",
  otherGame: "Andre spil",
  historySudoku: "Sudoku historie",
  rulesSudoku: "Regler for Sudoku",
  tipsSudoku: "Tips til Sudoku",
  sudokuTipsAndTrick: "Sudoku Tips og tricks - fra Noob til SudokuPro",
  goBack: "Gå tilbage",
  readBlog: "Læs blog",
  playSudokuOnline: "Spil Sudoku online",
  playNow: "SPIL NU",
  cookieText:
    "Vores websted bruger cookies til at tilpasse indholdet og analysere vores trafik, hvilket også kan resultere i profilering.",
  cancel: "Annuller",
  oops: "Ups...",
  errorPageNotFound: "404 fejl. Side ikke fundet",
  sorryGoBackHome: "Vi beklager, men siden du bad om kunne ikke findes. Gå venligst tilbage til hjemmesiden.",
  backToHomepage: "TILBAGE TIL FORSIDEN",
  similarArticles: "Lignende artikler",
  startYourBrainTraining:
    "Start din hjernetræning nu, download det gratis online spil Sudoku Pro, og klag ikke over at du ikke kan stoppe med at løse opgaverne.",
  //////// Meta and Description /////////
  metaPageTitle: "Sådan spiller du Sudoku - fra Noob til SudokuPro",
  metaPageDescription:
    "Læs SudokuPro-bloggen for at lære alle tips og tricks til at spille Sudoku, uanset om du er en absolut nybegynder eller en avanceret spiller. Få en nem forklaring på professionelle strategier til at spille mere effektivt og booste din hjernesundhed",
  metaBlogTitle: "Sudoku tips og tricks - fra noob til SudokuPro",
  metaBlogDescription:
    "Læs vores blog for at lære alle tricks i Sudoku for begyndere og erfarne spillere. Få en nem forklaring på professionelle strategier og tips til at løse Sudoku",

  ///////////Basic rules/////////////
  gameRules: {
    subtitle: "Sudoku gjort let",
    title: "Grundlæggende regler for Sudoku",
    titleText:
      "Hvis du er nybegynder i Sudoku-verdenen, er der intet at skamme sig over. Dette spil er meget logisk og nemt at få. Der er 3 grundregler, og afhængigt af hvilken type du vælger kan der være ændringer, men grundregelsættet ændres sjældent.",
    goal: "Mål:",
    goalText:
      "Udfyldelse af et 9×9 gitter, så hver kolonne, række og 3×3 sektion indeholder cifrene mellem 1 til 9. Traditionelt er der ingen tidsbegrænsning, men nogle Sudoku-spil for hjernen tilføjer tidsbegrænsninger, der presser dig til at tænke hurtigere.",
    ruleTitle: "Hvordan spiller man Sudoku?",
    ruleText:
      "Når du starter, vil nogle felter i 9×9-gitteret allerede have tal. Din opgave er at bruge din hjernekraft til at udfylde de manglende pladser og færdiggøre gitteret.",
    fail: "Du fejler, hvis:",
    list: "En række har to samme tal <list> En kolonne har to samme tal <list> Et 3×3 gitter har to samme tal <list> Et 3×3 gitter har to samme tal",
    remember: "Husk:",
    rememberText: "Hvert tal fra 1 til 9 kan kun bruges én gang i en række, kolonne eller 3×3 gitter",
  },

  /////////// Months ////////
  January: "Januar",
  February: "Februar",
  March: "Marts",
  April: "April",
  May: "Maj",
  June: "Juni",
  July: "Juli",
  August: "August",
  September: "September",
  October: "Oktober",
  November: "November",
  December: "December",
  /////Win poup////
  youWin: "Du vinder!!",
  youWinText: "Download appen for at spille når som helst og uden begrænsninger",
  followUs: "Følg os på de sociale medier:",
  winLevel: "Niveau",
  startNewGame: "Start nyt spil",
  time: "Tid",
  bestResult: "Bedste resultat",
  EASY: "Let",
  MEDIUM: "Medium",
  HARD: "Hårdt",
  EXPERT: "Ekspert",
  EXTREME: "Ekstrem",
  /////////NoGame RC//////
  sorry: "undskyld",
  gameNotFount: "spillet ikke fundet",
  chooseChallenge: "Vælg en udfordring her:",

  // Login Page
  loginMetaTitle: "Log ind på Sudoku Pro",
  loginMetaDescription: "Log ind for at gemme dine spilfremskridt og konkurrere med andre spillere",
  logIn: "Log på",
  welcomeBack: "Velkommen tilbage",
  continueWithGoogle: "Fortsæt med Google",
  continueWithFacebook: "Fortsæt med Facebook",
  authorizationFailedMessage: "Godkendelse mislykkedes. Prøv igen senere",

  // Registration Page
  registrationMetaTitle: "Afslut registrering - Sudoku Pro",
  registrationMetaDescription:
    "Der er kun et lille skridt tilbage for at fuldføre registreringen og få mest muligt ud af Sudoku-spillet",
  createAnAccount: "Opret en konto",
  nickName: "Kaldenavn",
  country: "Land",
  signUp: "Tilmelde",
  createYourNickName: "Opret dit kaldenavn",
  registrationFailedMessage: "Registreringen mislykkedes. Prøv igen senere",

  // Logout Dialog
  logOutMessage: "Er du sikker på, at du vil logge ud? Dine fremskridt vil ikke blive gemt",
  logOut: "Log ud",

  // Awards Page
  awardsMetaTitle: "Dine priser - Sudoku Pro",
  awardsMetaDescription: "Gennemfør de daglige udfordringer i Sudoku Pro og saml alle årets belønninger!",
  awards: "Priser",
  yourAwards: "Dine priser",
  yourAwardsDescription: "Gennemfør daglige udfordringer og få belønninger i slutningen af hver måned!",
  awardsDontMissASingleDay: "Gå ikke glip af en eneste dag - spil sudoku!",

  // Monthly Award Dialog
  awardDialogTitle: "Månedlig pris modtaget",
  awardDialogDescription: "Du har gennemført alle daglige udfordringer i en måned og modtaget en pris! Tillykke!",
  awardShare: "Del",

  // Authorization banner
  authorizationBannerMessage: "Log ind for at gemme dine fremskridt og priser 🏆",

  // You Won Dialog
  levelsLeftMessage: "Niveauer tilbage for at få belønningen: %s",

  // Ratings Block
  topCountriesToday: "TOP 20 lande i dag",
  topUsersToday: "TOP 100 brugere i dag",
  numberOfUsers: "Antal brugere",
  updatedEvery24hours: "Opdateres hver 24 timer",
  experiencePoints: "Erfaringspunkter",
  showFullList: "Vis hele listen",
  showShortList: "Vis en kort liste",
};

export const sv: any = {
  ISOcode: "sv",
  countryName: "Sweden",
  language: "Svenska",

  headerAndFooter: {
    moreGames: "Fler spel",
    playSudoku: "Spela Sudoku",
    playSolitaire: "Spela patiens",
    playMahjong: "Spela Mahjong",
  },

  dailyChallenges: "Daglig utmaning",
  dailyChallenge: "DAGLIG UTMANING",
  level: "Nivå: ",
  levels: "Nivåer",
  blog: "Blogg",
  privacyPolicy: "Integritetspolicy",
  howToPlay: "Hur man spelar",
  newGame: "NYTT SPEL",
  undo: "Avbryt",
  notes: "Anteckningar",
  hints: "Tips",
  erase: "Radera",
  nowDownload:
    "LADDA NER NU LADDA NER NU LADDA NER NU LADDA NER NU LADDA NER NU LADDA NER NU LADDA NER NU LADDA NER NU LADDA NER NU ",
  playBest: "Spela bäst",
  online: "Uppkopplad",
  playSudoku: "Sudoku!",
  seeWeToldYou: "Vi sa ju att reglerna är enkla!",
  dontWasteYourTime: "Slösa inte tid på att tvivla,",
  appIsSudokuPro: "den bästa Sudoku-appen är Sudoku Pro och du måste prova den!",
  playOnline: "spela online",
  points: "poäng",
  yes: "Ja",
  no: "Nej",
  playMahjong: "Spela Mahjong online",
  siteMap: "Webbplatskarta",
  otherGame: "Andra spel",
  historySudoku: "Sudoku historia",
  rulesSudoku: "Regler för Sudoku",
  tipsSudoku: "Tips för Sudoku",
  sudokuTipsAndTrick: "Sudoku online - tips och tricks, eller hur man blir professionell",
  goBack: "Tillbaka till toppen",
  readBlog: "Läs bloggen",
  playSudokuOnline: "Spela Sudoku online",
  playNow: "Spela nu",
  cookieText:
    "Genom att fortsätta surfa på webbplatsen bekräftar du att du har läst sekretesspolicyn och godkänner användningen av cookies.",
  cancel: "Avbryt",
  oops: "Oops...",
  errorPageNotFound: "404 fel. Sidan har inte hittats",
  sorryGoBackHome: "Tyvärr kunde sidan du begärde inte hittas. Vänligen återgå till huvudsidan.",
  backToHomepage: "TILLBAKA TILL HEMMET",
  similarArticles: "Similar Articles",
  startYourBrainTraining:
    "Börja utveckla din hjärna redan nu! Ladda ner det kostnadsfria onlinespelet Sudoku Pro och klaga inte på att du inte kan sluta lösa Sudoku-pussel.",
  //////// Meta and Description /////////
  metaPageTitle: "Hur man spelar Sudoku - från noob till Sudoku Pro",
  metaPageDescription:
    "Läs Sudoku Pro-bloggen för att få tips och tricks om hur du kan spela Sudoku som ett proffs, oavsett vilken nivå du har nu. Få en enkel förklaring av de komplexa strategierna i logikspelet för att spela effektivare och förbättra ditt minne.",
  metaBlogTitle: "Sudoku Online - tips och tricks | Från noob till Sudoku Pro",
  metaBlogDescription:
    "Läs vår Sudoku Pro-blogg för att lära dig alla funktioner i Sudoku för nybörjare och avancerade spelare. Få en enkel förklaring av professionella strategier och tips för att lösa Sudoku online.",
  /////////////Basic rules/////////////
  gameRules: {
    subtitle: "Sudoku är enkelt!",
    title: "Grundläggande regler för att spela Sudoku",
    titleText:
      "Om du är nybörjare och inte vet hur man löser Sudoku behöver du inte skämmas. Spelet är mycket logiskt och det är mycket lätt att lära sig spela Sudoku. Det finns tre grundregler och beroende på vilken typ av logikspel du väljer kan det finnas vissa nyanser, men de grundläggande reglerna för Sudoku är desamma.",
    goal: "Mål:",
    goalText:
      "Fyll 9×9 rutnätet så att varje kolumn, rad och 3×3-sektion innehåller siffror från 1 till 9. Traditionellt sett finns det ingen tidsgräns, men vissa Sudoku-spel online har en tidsgräns och verkar driva dig till att tänka snabbare.",
    ruleTitle: "HUR MAN SPELAR SUDOKU?",
    ruleText:
      "När du börjar kommer vissa rutor i rutnätet 9×9 redan att ha nummer. Din uppgift är att fylla i de saknade utrymmena utan att lämna några tomma utrymmen.",
    fail: "DU HAR MISSLYCKATS OM:",
    list: "Varje rad innehåller två identiska tal <list> Varje kolumn har två identiska tal <list> Varje 3×3-sektion har två identiska tal",
    remember: "KOM IHÅG:",
    rememberText: "Varje siffra från 1 till 9 kan endast användas en gång i en rad, kolumn eller 3×3-sektion.",
  },
  /////////// Months ////////
  January: "Januari",
  February: "Februari",
  March: "Mars",
  April: "April",
  May: "Maj",
  June: "Juni",
  July: "Juli",
  August: "Augusti",
  September: "September",
  October: "Oktober",
  November: "November",
  December: "December",
  /////Win poup////
  youWin: "Varning!",
  youWinText: "Ladda ner appen för att spela när som helst och utan några begränsningar",
  followUs: "Följ oss på de sociala nätverken:",
  winLevel: "Nivå",
  startNewGame: "Starta ett nytt spel",
  time: "Tid",
  bestResult: "Bästa resultat",
  EASY: "Ljus",
  MEDIUM: "Medium",
  HARD: "Tungt",
  EXPERT: "Expert",
  EXTREME: "Extremt",
  /////////NoGame RC//////
  sorry: "Tyvärr",
  gameNotFount: "spelet inte hittades",
  chooseChallenge: "Välj en utmaning:",

  // Login Page
  loginMetaTitle: "Logga in på Sudoku Pro",
  loginMetaDescription: "Logga in för att spara dina spelframsteg och tävla med andra spelare",
  logIn: "Logga in",
  welcomeBack: "Välkommen tillbaka",
  continueWithGoogle: "Fortsätt med Google",
  continueWithFacebook: "Fortsätt med Facebook",
  authorizationFailedMessage: "",

  // Registration Page
  registrationMetaTitle: "Avsluta registreringen - Sudoku Pro",
  registrationMetaDescription:
    "Det är bara ett litet steg kvar för att slutföra registreringen och få ut det mesta av Sudoku-spelet",
  createAnAccount: "Skapa ett konto",
  nickName: "Smeknamn",
  country: "Land",
  signUp: "Bli Medlem",
  createYourNickName: "Skapa ditt smeknamn",
  registrationFailedMessage: "Registreringen misslyckades, försök igen senare",

  // Logout Dialog
  logOutMessage: "Är du säker på att du vill logga ut? Dina framsteg kommer inte att sparas",
  logOut: "Logga ut",

  // Awards Page
  awardsMetaTitle: "Dina utmärkelser - Sudoku Pro",
  awardsMetaDescription: "Slutför de dagliga utmaningarna i Sudoku Pro och samla alla årets belöningar!",
  awards: "Utmärkelser",
  yourAwards: "Dina utmärkelser",
  yourAwardsDescription: "Slutför dagliga utmaningar och få belöningar i slutet av varje månad!",
  awardsDontMissASingleDay: "Missa inte en enda dag - spela sudoku!",

  // Monthly Award Dialog
  awardDialogTitle: "Månadspris mottagits",
  awardDialogDescription: "Du har klarat alla dagliga utmaningar under en månad och fått en utmärkelse! Grattis!",
  awardShare: "Dela med sig",

  // Authorization banner
  authorizationBannerMessage: "Logga in för att spara dina framsteg och utmärkelser 🏆",

  // You Won Dialog
  levelsLeftMessage: "Nivåer kvar för att få belöningen: %s",

  // Ratings Block
  topCountriesToday: "TOP 20 länderna idag",
  topUsersToday: "TOP 100 användare idag",
  numberOfUsers: "Antal användare",
  updatedEvery24hours: "Uppdateras var 24:e timme",
  experiencePoints: "Erfarenhetspoäng",
  showFullList: "Visa hela listan",
  showShortList: "Visa en kort lista",
};

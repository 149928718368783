export const cs: any = {
  ISOcode: "cs",
  countryName: "Czech",
  language: "čeština",

  headerAndFooter: {
    moreGames: "Další hry",
    playSudoku: "Hrát sudoku",
    playSolitaire: "Zahrajte si Solitaire",
    playMahjong: "Hrát Mahjong",
  },

  dailyChallenges: "Denní výzvy",
  dailyChallenge: "VYZKOUŠEJTE SI DENNÍ VÝZVU",
  level: "Úroveň: ",
  levels: "úrovně",
  blog: "Blog",
  privacyPolicy: "Zásady ochrany osobních údajů",
  howToPlay: "Jak hrát",
  newGame: "NOVÁ HRA",
  undo: "Zrušit",
  notes: "Poznámky",
  hints: "Nápovědy",
  erase: "Vymazat",
  nowDownload:
    "NYNÍ STAHOVAT NYNÍ STAHOVAT NYNÍ STAHOVAT NYNÍ STAHOVAT NYNÍ STAHOVAT NYNÍ STAHOVAT NYNÍ STAHOVAT NYNÍ STAHOVAT NYNÍ STAHOVAT ",
  playBest: "Hrajte nejlépe",
  online: "Online",
  playSudoku: "Sudoku!",
  seeWeToldYou: "Vidíte, říkali jsme vám, že pravidla jsou snadná!",
  dontWasteYourTime: "  Teď už neztrácejte čas pochybnostmi, ",
  appIsSudokuPro: "nejlepší aplikace sudoku je Sudoku Pro a musíte ji vyzkoušet!",
  playOnline: "hrát online",
  points: "body",
  yes: "Ano",
  no: "Ne",
  playMahjong: "Hrát Mahjong online",
  siteMap: "Mapa stránek",
  otherGame: "Jiné hry",
  historySudoku: "Historie sudoku",
  rulesSudoku: "Pravidla pro sudoku",
  tipsSudoku: "Tipy pro sudoku",

  sudokuTipsAndTrick: "Tipy a triky na sudoku - Od nooba k SudokuPro",
  goBack: "Zpět",
  readBlog: "Přečtěte si blog",
  playSudokuOnline: "Hrajte sudoku online",
  playNow: "HRAJTE NYNÍ",
  cookieText:
    "Naše webové stránky používají soubory cookie k personalizaci obsahu a analýze návštěvnosti, což může vést k profilování.",
  cancel: "Zrušit",
  oops: "Ooops...",
  errorPageNotFound: "Chyba 404. Stránka nebyla nalezena",
  sorryGoBackHome:
    "Je nám líto, ale vámi požadovanou stránku se nepodařilo najít. Vraťte se prosím na domovskou stránku.",
  backToHomepage: "ZPĚT NA DOMOVSKOU STRÁNKU",
  similarArticles: "Podobné články",
  startYourBrainTraining:
    "Začněte trénovat svůj mozek hned teď, stáhněte si zdarma online hru Sudoku Pro a nestěžujte si, že nemůžete přestat řešit.",
  //////// Meta and Description /////////
  metaPageTitle: "Jak hrát sudoku - od začátečníka k SudokuPro",
  metaPageDescription:
    "Přečtěte si blog SudokuPro, kde se dozvíte všechny tipy a triky pro hraní sudoku, ať už jste úplný začátečník nebo pokročilý hráč. Získejte snadné vysvětlení profesionálních strategií pro efektivnější hraní a posílení zdraví vašeho mozku.",

  metaBlogTitle: "Tipy a triky pro řešení sudoku - od nooba po SudokuPro",
  metaBlogDescription:
    "Přečtěte si náš blog a naučte se všechny triky v Sudoku pro začátečníky i zkušené hráče. Získejte snadné vysvětlení profesionálních strategií a tipů pro řešení sudoku.",

  /////////////Basic rules/////////////
  gameRules: {
    subtitle: "Snadné sudoku!",
    title: "Základní pravidla sudoku",
    titleText:
      "Pokud jste ve světě Sudoku nováčkem, nemusíte se za nic stydět. Tato hra je velmi logická a snadno se do ní dostanete. Existují 3 základní pravidla a v závislosti na typu, který si vyberete, může dojít ke změnám, nicméně základní sada pravidel se mění jen zřídka.",
    goal: "Cíl:",
    goalText:
      "Vyplňte mřížku 9×9 tak, aby každý sloupec, řádek a výseč 3×3 obsahovaly číslice od 1 do 9. V každém sloupci, řádku a výseči 3×3 jsou číslice od 1 do 9. Tradičně neexistuje žádný časový limit, ale některé hry Sudoku pro mozek přidávají časová omezení, která nutí k rychlejšímu přemýšlení.",
    ruleTitle: "Jak hrát Sudoku?",
    ruleText:
      "Když začnete, některé čtverce mřížky 9×9 již budou obsahovat číslice. Vaším úkolem je pomocí mozkových závitů doplnit chybějící místa a mřížku dokončit.",
    fail: "Neuspějete, pokud:",
    list: "libovolný řádek obsahuje dvě stejná čísla <list> libovolný sloupec obsahuje dvě stejná čísla <list> libovolná mřížka 3×3 obsahuje dvě stejná čísla.",
    remember: "Nezapomeňte:",
    rememberText: "Každá číslice od 1 do 9 může být v řádku, sloupci nebo mřížce 3×3 použita pouze jednou.",
  },

  /////////// Months ////////
  January: "Leden",
  February: "Únor",
  March: "Březen",
  April: "Duben",
  May: "Květen",
  June: "Červen",
  July: "Červenec",
  August: "Srpen",
  September: "Září",
  October: "Říjen",
  November: "Listopad",
  December: "Prosinec",

  /////Win poup////
  youWin: "Vyhrál jsi!",
  youWinText: "Stáhněte si aplikaci a hrajte kdykoli a bez jakýchkoli omezení",
  followUs: "Sledujte nás na sociálních sítích:",
  winLevel: "Úroveň",
  startNewGame: "Spustit novou hru",
  time: "Čas",
  bestResult: "Nejlepší výsledek",
  EASY: "Snadný",
  MEDIUM: "Střední",
  HARD: "Těžké",
  EXPERT: "Expert",
  EXTREME: "Extrémní",
  /////////NoGame RC//////
  sorry: "Omlouváme se",
  gameNotFount: "hra nebyla nalezena",
  chooseChallenge: "Vyberte si výzvu zde:",

  // Login Page
  loginMetaTitle: "Přihlaste se do Sudoku Pro",
  loginMetaDescription: "Přihlaste se, abyste si uložili svůj postup ve hře a soutěžili s ostatními hráči",
  logIn: "Přihlásit se",
  welcomeBack: "Vítej zpět",
  continueWithGoogle: "Pokračujte s Googlem",
  continueWithFacebook: "Pokračujte na Facebooku",
  authorizationFailedMessage: "Autorizace se nezdařila, zkuste to znovu později",

  // Registration Page
  registrationMetaTitle: "Dokončit registraci - Sudoku Pro",
  registrationMetaDescription: "K dokončení registrace a maximálnímu využití hry Sudoku zbývá už jen malý krůček",
  createAnAccount: "Vytvořit účet",
  nickName: "Přezdívka",
  country: "Země",
  signUp: "Přihlásit se",
  createYourNickName: "Vytvořte si přezdívku",
  registrationFailedMessage: "Registrace se nezdařila, zkuste to znovu později",

  // Logout Dialog
  logOutMessage: "Are you sure you want to log out? Your progress will not be saved",
  logOut: "Odhlásit se",

  // Awards Page
  awardsMetaTitle: "Vaše ocenění - Sudoku Pro",
  awardsMetaDescription: "Dokončete každodenní výzvy v Sudoku Pro a získejte všechny odměny za rok!",
  awards: "Ocenění",
  yourAwards: "Vaše ocenění",
  yourAwardsDescription: "Dokončete každodenní výzvy a získejte odměny na konci každého měsíce!",
  awardsDontMissASingleDay: "Nenechte si ujít jediný den – hrajte sudoku!",

  // Monthly Award Dialog
  awardDialogTitle: "Obdržená měsíční cena",
  awardDialogDescription: "Splnili jste všechny denní výzvy po dobu jednoho měsíce a získali jste cenu! Gratulujeme!",
  awardShare: "Podíl",

  // Authorization banner
  authorizationBannerMessage: "Přihlaste se a uložte si svůj postup a ocenění 🏆",

  // You Won Dialog
  levelsLeftMessage: "Zbývající úrovně pro získání odměny: %s",

  // Ratings Block
  topCountriesToday: "TOP 20 zemí dneška",
  topUsersToday: "TOP 100 dnešních uživatelů",
  numberOfUsers: "Počet uživatelů",
  updatedEvery24hours: "Aktualizováno každých 24 hodin",
  experiencePoints: "Zkušenostní body",
  showFullList: "Zobrazit úplný seznam",
  showShortList: "Zobrazit krátký seznam",
};

export const vi: any = {
  ISOcode: "vi",
  countryName: "Việt Nam",
  language: "Tiếng Việt",

  headerAndFooter: {
    moreGames: "Thêm trò chơi",
    playSudoku: "Chơi Sudoku",
    playSolitaire: "Chơi bài Solitaire",
    playMahjong: "Chơi Mahjong",
  },

  blog: "Blog",
  dailyChallenges: "Thử thách hằng ngày",
  dailyChallenge: "THỬ THÁCH HẰNG NGÀY",
  readBlog: "Đọc blog",
  omisoft: "OmiSoft",
  sudokuPuzzleGame: "Sudoku Pro — Trò chơi giải đố",
  sudokuTipsAndTrick: "Sudoku trực tuyến - mẹo và thủ thuật hoặc cách trở thành chuyên gia",
  oops: "Ối...",
  errorPageNotFound: "lỗi 404. Không tìm thấy trang",
  sorryGoBackHome: "Chúng tôi xin lỗi, nhưng không thể tìm thấy trang bạn yêu cầu. Vui lòng quay lại trang chủ.",
  backToHomepage: "QUAY LẠI TRANG CHỦ",
  cookieText:
    "Trang web của chúng tôi sử dụng cookie để cá nhân hóa nội dung và phân tích lưu lượng truy cập, điều này cũng có thể dẫn đến việc lập hồ sơ.",
  ok: "ĐƯỢC RỒI",
  privacyPolicy: "Chính sách bảo mật",
  howToPlay: "Cách chơi",
  undo: "Hoàn tác",
  notes: "Ghi chú",
  hints: "gợi ý",
  erase: "Tẩy xóa",
  newGame: "Trò chơi mới",
  level: "Level: ",
  levels: "Cấp độ",
  similarArticles: "Bài viết tương tự",
  playSudokuOnline: "Chơi Sudoku trực tuyến",
  startYourBrainTraining:
    "Hãy bắt đầu rèn luyện trí não của bạn ngay bây giờ, tải xuống trò chơi trực tuyến miễn phí Sudoku Pro và đừng phàn nàn rằng bạn không thể ngừng giải quyết.",
  nowDownload: "TẢI NGAY TẢI NGAY TẢI NGAY TẢI NGAY TẢI NGAY",
  playBest: "Chơi tốt nhất",
  online: "Trực tuyến",
  playSudoku: "Sudoku!",
  seeWeToldYou: "Hãy xem, chúng tôi đã nói với bạn rằng các quy tắc rất dễ dàng!",
  dontWasteYourTime: "Bây giờ, đừng lãng phí thời gian để nghi ngờ, ứng dụng sudoku tốt nhất",
  appIsSudokuPro: "là Sudoku Pro và bạn phải thử nó!",
  goBack: "Quay lại",
  facebook: "Facebook",
  twitter: "Twitter",
  telegram: "Telegram",
  points: "điểm",
  yes: "Đúng",
  no: "KHÔNG",
  playMahjong: "Chơi mạt chược trực tuyến",
  siteMap: "Sơ đồ trang web",
  otherGame: "Các trò chơi khác",
  historySudoku: "Lịch sử Sudoku",
  rulesSudoku: "Quy tắc cho Sudoku",
  tipsSudoku: "Lời khuyên cho Sudoku",
  /////Win poup////
  youWin: "Bạn đã thắng!",
  youWinText: "Tải ứng dụng để chơi bất cứ lúc nào với nhiều lợi ích",
  followUs: "Theo dõi chúng tôi trên mạng xã hội:",
  winLevel: "Mức độ",
  startNewGame: "Bắt đầu trò chơi mới",
  time: "Thời gian",
  bestResult: "Kết quả tốt nhất",
  ////////////////////////
  playOnline: "Chơi trực tuyến",
  cancel: "Hủy bỏ",
  //////// Meta and Description /////////

  metaPageTitle: "Cách chơi Sudoku — Từ Noob đến SudokuPro",
  metaPageDescription:
    "Đọc blog SudokuPro để tìm hiểu tất cả các mẹo và thủ thuật chơi Sudoku bất kể bạn là người mới bắt đầu hay người chơi nâng cao. Nhận lời giải thích dễ dàng về các chiến lược chuyên nghiệp để chơi hiệu quả hơn và tăng cường sức khỏe não bộ của bạn",

  metaBlogTitle: "Mẹo và thủ thuật Sudoku — Từ Noob đến SudokuPro",
  metaBlogDescription:
    "Đọc blog của chúng tôi để tìm hiểu tất cả các thủ thuật trong Sudoku dành cho người mới bắt đầu và người chơi chuyên nghiệp. Nhận lời giải thích dễ dàng về các chiến lược và mẹo chuyên nghiệp để giải Sudoku",

  //////Basic rules///////

  gameRules: {
    subtitle: "Sudoku thật dễ dàng",
    title: "Luật cơ bản của Sudoku",
    titleText:
      "Nếu bạn là người mới làm quen với thế giới Sudoku thì không có gì phải xấu hổ. Trò chơi này rất logic và dễ dàng để có được. Có 3 quy tắc cơ bản và tùy thuộc vào loại bạn chọn mà có thể có những thay đổi, tuy nhiên, các quy tắc cơ bản được đặt ra hiếm khi thay đổi.",
    goal: "Mục tiêu:",
    goalText:
      "Điền vào lưới 9×9, sao cho mỗi cột, hàng và phần 3×3 chứa các chữ số từ 1 đến 9. Theo truyền thống, không có giới hạn thời gian, nhưng một số trò chơi Sudoku dành cho trí não có thêm giới hạn thời gian để thúc đẩy suy nghĩ nhanh hơn.",
    ruleTitle: "Làm thế nào để chơi Sudoku?",
    ruleText:
      "Khi bạn bắt đầu, một số ô vuông của lưới 9×9 sẽ có sẵn các chữ số. Nhiệm vụ của bạn là sử dụng trí tuệ của mình để điền vào những chỗ còn thiếu và hoàn thiện lưới điện.",
    fail: "Bạn thất bại nếu:",
    list: "Bất kỳ hàng nào cũng có hai số giống nhau <danh sách> Bất kỳ cột nào cũng có hai số giống nhau <danh sách> Bất kỳ lưới 3×3 nào cũng có hai số giống nhau",
    remember: "Nhớ:",
    rememberText: "Mỗi chữ số từ 1 đến 9 chỉ có thể được sử dụng một lần trên một hàng, cột hoặc lưới 3×3",
  },

  playNow: "BẮT ĐẦU CHƠI",
  /////////// Months ////////
  January: "Tháng Giêng",
  February: "Tháng Hai",
  March: "Tháng Ba",
  April: "Tháng Tư",
  May: "Tháng Năm",
  June: "Tháng Sáu",
  July: "Tháng Bảy",
  August: "Tháng Tám",
  September: "Tháng Chín",
  October: "Tháng Mười",
  November: "Tháng Mười một",
  December: "Tháng Mười hai",
  ///////// Game levels //////
  EASY: "Dễ",
  MEDIUM: "Trung bình",
  HARD: "Cứng",
  EXPERT: "Chuyên gia",
  EXTREME: "Vô cùng",
  /////////NoGame RC//////
  sorry: "Xin lỗi",
  gameNotFount: "trò chơi không tìm thấy",
  chooseChallenge: "Chọn thử thách ở đây:",

  // Login Page
  loginMetaTitle: "Đăng nhập vào Sudoku Pro",
  loginMetaDescription: "Đăng nhập để lưu tiến trình trò chơi của bạn và cạnh tranh với những người chơi khác",
  logIn: "Đăng nhập",
  welcomeBack: "Chào mừng trở lại",
  continueWithGoogle: "Đăng nhập bằng Google",
  continueWithFacebook: "Đăng nhập Facebook",
  authInfoModalTitleFailed: "Ủy quyển thất bại",
  authorizationFailedMessage: "Ủy quyền không thành công, hãy thử lại sau",

  // Registration Page
  registrationMetaTitle: "Hoàn tất đăng ký - Sudoku Pro",
  registrationMetaDescription: "Chỉ còn một bước nhỏ nữa là hoàn tất đăng ký và tận dụng tối đa trò chơi Sudoku",
  createAnAccount: "Tạo một tài khoản",
  nickName: "Tên nick",
  country: "Quốc gia",
  signUp: "Đăng ký",
  createYourNickName: "Tạo biệt hiệu của bạn",
  authInfoModalRegistrationTitleErrors: "Đăng ký không thành công",
  registrationFailedMessage: "Đăng ký không thành công, hãy thử lại sau",

  // Logout Dialog
  logOutMessage: "Bạn có chắc chắn bạn muốn thoát? Tiến trình của bạn sẽ không được lưu",
  logOut: "Đăng xuất",

  // Awards Page
  awardsMetaTitle: "Giải thưởng của bạn - Sudoku Pro",
  awardsMetaDescription:
    "Hoàn thành các thử thách hàng ngày trong Sudoku Pro và thu thập tất cả phần thưởng trong năm!",
  awards: "giải thưởng",
  yourAwards: "Giải thưởng của bạn",
  yourAwardsDescription: "Hoàn thành các thử thách hàng ngày và nhận phần thưởng vào cuối mỗi tháng!",
  awardsDontMissASingleDay: "Đừng bỏ lỡ một ngày nào - chơi sudoku!",
  getAwards: "Nhận giải thưởng!",

  // Monthly Award Dialog
  awardDialogTitle: "Đã nhận được giải thưởng hàng tháng",
  awardDialogDescription:
    "Bạn đã hoàn thành tất cả các thử thách hàng ngày trong một tháng và nhận được giải thưởng! Chúc mừng!",
  awardShare: "Chia sẻ",

  // Authorization banner
  authorizationBannerMessage: "Đăng nhập để lưu tiến trình và giải thưởng của bạn 🏆",

  // You Won Dialog
  levelsLeftMessage: "🏆Cấp độ còn lại để nhận phần thưởng: %s",
  finalPoints: "Final points",

  // Ratings Block
  topCountriesToday: "TOP 20 quốc gia hiện nay",
  topUsersToday: "TOP 100 người dùng hôm nay",
  numberOfUsers: "Số lượng người dùng",
  updatedEvery24hours: "Cập nhật 24 giờ một lần",
  experiencePoints: "Điểm kinh nghiệm",
  showFullList: "Hiển thị danh sách đầy đủ",
  showShortList: "Hiển thị danh sách ngắn",

  // ControlHintsModalWarning
  controlHintsModalWarningMessage: "Tính năng này không có trong thử thách hàng ngày",

  // ConsecutiveDaysWarningModal
  consecutiveDaysModalText: "Số ngày thi đấu liên tiếp. Chơi mỗi ngày để cải thiện thành tích này!",
  consecutiveDaysModalTextLogIn: "Đăng nhập để lưu thành tích của bạn",
};
